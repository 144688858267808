<template>
  <div class="page_box">
    <el-card
      class="box-card"
      shadow="never"
      v-for="(item1, index1) in state.config"
      :key="index1"
    >
      <template #header>
        <div class="card_box">
          <div class="card_header">
            {{ item1.head_config.title }}
            <span
              v-if="item1.head_config.red_tip && item1.head_config.type === 3"
            >
              {{ item1.head_config.red_tip }}
            </span>
          </div>
          <el-button v-if="item1.head_config.isBackBtn" @click="onBack"
            >返回</el-button
          >
          <div class="look_btn" v-if="item1.head_config.type === 3">
            <el-button
              v-show="isCancel(item1.head_config.btn_array)"
              @click="onCancel(item1)"
              >取消</el-button
            >
            <el-button
              type="primary"
              v-for="(item2, index2) in item1.head_config.btn_array"
              :key="index2"
              v-show="item2.is"
              @click="onSubmit(item2)"
              >提交</el-button
            >
          </div>
          <div
            class="look_btn"
            v-if="
              !isCancel(item1.head_config.btn_array) &&
              item1.head_config.type === 3
            "
          >
            <el-button
              type="primary"
              v-for="(item2, index2) in item1.head_config.btn_array"
              :key="index2"
              :disabled="item2.disabled"
              @click="onBtn(item2, item1, index2, index1)"
              >{{ item2.text }}</el-button
            >
          </div>
        </div>
      </template>
      <el-row :gutter="20" v-if="item1.type === 'slot'">
        <slot :name="item1.slot_name"></slot>
      </el-row>
      <el-row :gutter="20" v-else>
        <template v-for="(item2, index2) in item1.body_config">
          <!-- text -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-if="isModuleShow(item1, item2, 'text')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">{{
              item2.value
            }}</el-form-item>
          </el-col>
          <!-- input -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'input')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <ToolTipCom v-model="item1.body_config[index2]">
                <div v-show="item2.islook">{{ item2.value }}</div>
                <el-input
                  v-model.trim="item2.value"
                  :type="'text'"
                  placeholder="请输入"
                  v-show="!item2.islook"
                  @input="onInput(item2)"
                  :disabled="item2.disabled"
                  @change="onChange('input', item1, item2)"
                />
              </ToolTipCom>
            </el-form-item>
          </el-col>
          <!-- inputlabel -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'inputlabel')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <div class="form_box">
                <div class="form_title">{{ item2.title }}</div>
                <div class="form_padding" v-show="item2.islook">
                  {{ item2.value }}
                </div>
                <div class="form_input" v-show="!item2.islook">
                  <el-input
                    v-model.trim="item2.value"
                    :type="'text'"
                    placeholder="请输入"
                    @input="onInput(item2)"
                    @change="onChange('inputlabel', item1, item2)"
                  />
                </div>
                <div class="form_unit">{{ item2.unit }}</div>
              </div>
            </el-form-item>
          </el-col>
          <!-- selectlabel -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'selectlabel')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <div class="form_box">
                <div class="form_select">
                  <el-select
                    v-model="item2.selectValue"
                    placeholder="请选择"
                    :class="{ form_input_look: item2.islook }"
                    :disabled="item2.islook"
                    @change="onChange('selectlabel', item1, item2)"
                  >
                    <el-option
                      v-for="item in item2.selectList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </div>
                <div class="form_title">{{ item2.title }}</div>
                <div class="form_padding" v-show="item2.islook">
                  {{ item2.value }}
                </div>
                <div class="form_input" v-show="!item2.islook">
                  <el-input
                    v-model.trim="item2.value"
                    :type="'text'"
                    placeholder="请输入"
                    @input="onInput(item2)"
                    @change="onChange('selectlabel', item1, item2)"
                  />
                </div>
                <div class="form_unit">{{ item2.unit }}</div>
              </div>
            </el-form-item>
          </el-col>
          <!-- select -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'select')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <ToolTipCom v-model="item1.body_config[index2]">
                <!-- <div v-show="item2.islook">
                  {{ onSelectText(item2.value, item2.select_list) }}
                </div> -->
                <el-select
                  v-model.trim="item2.value"
                  placeholder="请选择"
                  :multiple="item2.multiple"
                  @change="onChange('select', item1, item2)"
                  filterable
                  :class="{ form_input_look: item2.islook }"
                  :disabled="item2.islook"
                >
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    v-for="item in item2.select_list"
                    :key="item.value"
                  />
                </el-select>
              </ToolTipCom>
            </el-form-item>
          </el-col>
          <!-- select-v2 -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'select-v2')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <ToolTipCom v-model="item1.body_config[index2]">
                <div v-show="item2.islook">
                  {{ onSelectText(item2.value, item2.select_list) }}
                </div>
                <el-select-v2
                  v-model.trim="item2.value"
                  placeholder="请选择"
                  v-show="!item2.islook"
                  :multiple="item2.multiple"
                  @change="onChange('select-v2', item1, item2)"
                  filterable
                  :options="item2.select_list"
                />
              </ToolTipCom>
            </el-form-item>
          </el-col>
          <!-- radio -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'radio')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <div v-show="item2.islook">
                {{ onSelectText(item2.value, item2.radio_list) }}
              </div>
              <el-radio-group
                v-model.trim="item2.value"
                v-show="!item2.islook"
                @change="onRadioChange(item1, item2)"
              >
                <el-radio
                  :label="item3.value"
                  v-for="item3 in item2.radio_list"
                  :key="item3.value"
                  :disabled="item3.disabled"
                  >{{ item3.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- radiolabel -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'radiolabel')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <div class="form_box" v-show="item2.islook">
                <div class="form_front">
                  {{ onRadioText(item2.value, item2.radiolabel_list).label }}
                </div>
                <div class="form_title">
                  {{ onRadioText(item2.value, item2.radiolabel_list).title }}
                </div>
                <div class="form_padding">
                  {{
                    onRadiolabelText(item2.value, item2.radiolabel_list).value2
                  }}
                </div>
                <div class="form_unit">
                  {{ onRadioText(item2.value, item2.radiolabel_list).unit }}
                </div>
              </div>
              <el-radio-group
                v-model.trim="item2.value"
                v-show="!item2.islook"
                @change="onChange('radiolabel', item1, item2)"
              >
                <el-radio
                  :label="item3.value"
                  v-for="item3 in item2.radiolabel_list"
                  :key="item3.value"
                >
                  <div class="form_box">
                    <div class="form_front">{{ item3.label }}</div>
                    <div class="form_title">{{ item3.title }}</div>
                    <div class="form_input">
                      <el-input
                        v-model.trim="item3.value2"
                        placeholder="请输入"
                        :type="item3.comType || 'text'"
                        @change="onChange('radiolabel', item1, item2)"
                      />
                    </div>
                    <div class="form_unit">{{ item3.unit }}</div>
                  </div>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <!-- date -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'date')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <div v-show="item2.islook">
                {{ globalGetdate(item2.value).date }}
              </div>
              <div v-show="!item2.islook">
                <el-date-picker
                  v-model.trim="item2.value"
                  type="date"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  placeholder="请输入"
                  v-if="isDisabledDate(item2.label)"
                  :disabled-date="disabledDate"
                  @change="onChange('date', item1, item2)"
                />
                <el-date-picker
                  v-model.trim="item2.value"
                  type="date"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  placeholder="请输入"
                  v-else
                  @change="onChange('date', item1, item2)"
                />
              </div>
            </el-form-item>
          </el-col>
          <!-- datetime -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'datetime')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <div v-show="item2.islook">
                {{ globalGetdate(item2.value).long }}
              </div>
              <div v-show="!item2.islook">
                <el-date-picker
                  v-model.trim="item2.value"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  placeholder="请输入"
                  v-if="isDisabledDate(item2.label)"
                  :disabled-date="disabledDate"
                />
                <el-date-picker
                  v-model.trim="item2.value"
                  type="datetime"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  placeholder="请输入"
                  v-else
                />
              </div>
            </el-form-item>
          </el-col>
          <!-- datepicker -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'datepicker')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <div v-show="item2.islook">
                {{ onDatepicker(item2.value, 1) }}
              </div>
              <div v-show="!item2.islook">
                <el-date-picker
                  v-model.trim="item2.value"
                  type="daterange"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="截止日期"
                  @change="onChange('datepicker', item1, item2)"
                />
              </div>
            </el-form-item>
          </el-col>
          <!-- datetimerange -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'datetimerange')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <div v-show="item2.islook">
                {{ onDatepicker(item2.value, 2) }}
              </div>
              <div v-show="!item2.islook">
                <el-date-picker
                  v-model.trim="item2.value"
                  type="datetimerange"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="截止日期"
                  :default-time="[
                    new Date(2000, 1, 1, 0, 0, 0),
                    new Date(2000, 1, 1, 23, 59, 59),
                  ]"
                />
              </div>
            </el-form-item>
          </el-col>
          <!-- timepicker -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'timepicker')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <div v-show="item2.islook">
                {{ onDatepicker(item2.value, 3) }}
              </div>
              <div v-show="!item2.islook">
                <el-time-picker
                  v-model.trim="item2.value"
                  is-range
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  value-format="HH:mm"
                  format="HH:mm"
                  @change="onChange('timepicker', item1, item2)"
                />
              </div>
            </el-form-item>
          </el-col>
          <!-- uploadimage -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'uploadimage')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <PictureUploadComOnly
                @success="onUploadSuccess($event, item2)"
                :img_key="item2.value"
                :islook="item2.islook"
                :type="item2.imgtype"
              />
            </el-form-item>
          </el-col>
          <!-- uploadimagemore -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'uploadimagemore')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <PictureUploadComMultiple
                @success="onUploadSuccess($event, item2)"
                :file_list="item2.value"
                :islook="item2.islook"
              />
            </el-form-item>
          </el-col>
          <!-- uploadfile -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'uploadfile')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <PictureUploadComOnly
                :type="3"
                accept=".pdf"
                text="支持 PDF 格式，且不超过10MB"
                :img_key="item2.value"
                @success="onUploadSuccess($event, item2)"
                :islook="item2.islook"
              />
            </el-form-item>
          </el-col>
          <!-- uploadfilemore -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'uploadfilemore')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <PictureUploadComMultiple
                :file_list="item2.value"
                :type="3"
                accept=".pdf"
                text="支持 PDF 格式，且不超过10MB"
                @success="onUploadSuccess($event, item2)"
                :islook="item2.islook"
              />
            </el-form-item>
          </el-col>
          <!-- lonlat -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'lonlat')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <div class="form_box">
                <div v-if="item2.islook">{{ item2.value }}</div>
                <el-input
                  v-model.trim="item2.value"
                  placeholder="请输入油站实际经营位置的经纬度"
                  v-else
                />
              </div>
              <div
                class="form_location"
                @click="onOpenAMap(item1, item2)"
                v-if="!item2.islook"
              >
                <el-tooltip
                  effect="dark"
                  content="<div>经纬度使用小技巧：</div><div>1. 信息准确度：搜索关键字越多越准确，搜索准确度高</div><div>2. 经纬度精度说明：经纬度的精度通常到小数点后6位，可精确到米级别</div>"
                  placement="top-start"
                  :raw-content="true"
                >
                  <svg-icon icon-class="position" />
                </el-tooltip>
              </div>
            </el-form-item>
          </el-col>
          <!-- city -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'city')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <ToolTipCom v-model="item1.body_config[index2]">
                <div v-show="item2.islook">{{ onCity(item2.value) }}</div>
                <div v-show="!item2.islook" style="width: 100%">
                  <el-cascader
                    placeholder="请选择"
                    style="width: 100%"
                    :options="state.options"
                    v-model.trim="item2.value"
                    :disabled="item2.islook"
                    @change="onChange('city', item1, item2)"
                  />
                </div>
              </ToolTipCom>
            </el-form-item>
          </el-col>
          <!-- city_more -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'city_more')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <div v-show="item2.islook">{{ onCityMore(item2.value) }}</div>
              <div v-show="!item2.islook" style="width: 100%">
                <el-cascader
                  placeholder="请选择"
                  style="width: 100%"
                  :options="state.options"
                  :props="{ multiple: true }"
                  v-model.trim="item2.value"
                  :disabled="item2.islook"
                />
              </div>
            </el-form-item>
          </el-col>
          <!-- cascader -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'cascader')"
          >
            <el-form-item :label="item2.label" :label-width="item2.width">
              <div v-show="item2.islook" class="cascader_box">
                <el-cascader
                  placeholder="请选择"
                  style="width: 100%"
                  :options="item2.cascader_list"
                  v-model.trim="item2.value"
                  separator=" "
                  disabled
                />
              </div>
              <div v-show="!item2.islook" style="width: 100%">
                <el-cascader
                  placeholder="请选择"
                  style="width: 100%"
                  :options="item2.cascader_list"
                  v-model.trim="item2.value"
                />
              </div>
            </el-form-item>
          </el-col>
          <!-- empty -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'empty')"
          ></el-col>
          <!-- titlt -->
          <el-col
            :span="item2.span"
            :xs="24"
            :sm="24"
            :lg="item2.span"
            v-else-if="isModuleShow(item1, item2, 'titlt')"
          >
            <div class="card_box">
              <div class="card_header card_header2">{{ item2.title }}</div>
              <el-button type="primary" class="look_btn" link>
                {{ item2.btn_text }}
                <ImagePreview
                  class="look_img"
                  :src="item2.btn_url"
                  width="100%"
                  height="100%"
                />
              </el-button>
            </div>
          </el-col>
          <!-- end -->
        </template>
      </el-row>
    </el-card>

    <div class="map_box" v-show="state.isAMap">
      <AmapLatLonCom
        @shut="state.isAMap = false"
        @success="onAMapSuccess"
        v-if="state.isAMap"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, watch, computed } from "vue";
import {
  globalGetdate,
  onSelectText,
  onInputFormat,
  globalAddressAreas,
  globalGetAddressAreas,
} from "@/utils/date_tools";
import { useRouter, useRoute } from "vue-router";
import ToolTipCom from "./ToolTipCom/index.vue";
const router = useRouter();
const route = useRoute();
const apply_type = route.query.applyType;

const props = defineProps({
  // 网页渲染配置文件
  config: {
    type: Object,
    default: () => {},
  },
  // 后台接口文件
  base_api: {
    type: Object,
    default: undefined,
  },
  // 初始编辑类型，编辑模式，页面默认编辑值
  type: {
    type: [Number, String],
    default: 99999999,
  },
  type_list: {
    type: Array,
    default: () => [],
  },
  // 等同于各模块 type，优先级高于各级 type，默认值不在范围内，目前范围 1-3
  page_type: {
    type: Number,
    default: 99999999,
  },
  // 请求赋值props无效，解决请求数据返回渲染延迟，导致编辑按钮闪烁问题，本质就是组件默认展示编辑按钮导致
  //  且二次传递config并没有刷新组件
  refresh: Boolean,
});
const emits = defineEmits(["submit"]);
// 双日期修改
const double_argument_config = ["datepicker", "datetimerange", "timepicker"];

const state = reactive({
  config: {} as any,

  api_data: {},

  isAMap: false,
  AMapData1: {},
  AMapData2: {}, // 保存item2
  options: [],
});

onMounted(async () => {
  const options = await globalAddressAreas();
  state.options = options;
});

// 提交按钮
function onSubmit(data: any) {
  onExportKey(data.value);
  emits("submit", state.api_data);
}

// 取消按钮操作
function onCancel(data: any) {
  // 取消全局按钮操作
  state.config = state.config.map((item) => {
    item.head_config.btn_array = item.head_config.btn_array.map((item) => {
      item.is = false;
      return item;
    });
    return item;
  });
  data.body_config.forEach((item2) => (item2.islook = true));
  // 重置数据
  watchInit(props, true);
}

// 按钮控制
function onBtn(data: any, item1: any, index2: any, index1: any) {
  if (data.is_submit) {
    onSubmit(data);
    return false;
  }
  // 解决引用链断开引起的关闭问题
  onCancel(item1);
  const obj = state.config[index1].head_config.btn_array[index2];
  obj.is = true;
  // data.is = !data.is;
  onType3Format(state.config[index1]);
}

function onType3Format(item: any) {
  const btn_array = item.head_config.btn_array.filter((item) => item.is);
  if (btn_array.length > 0) {
    const { isAuth, auth_list } = btn_array[0];
    if (isAuth) {
      item.body_config.forEach((item2) => (item2.islook = false));
    } else {
      item.body_config.forEach((item2) => {
        const is = auth_list.includes(item2.key_name);
        if (is) {
          item2.islook = false;
        }
      });
    }
  }
  return item;
}

// 更改处理
async function onChange(type, data, data2) {
  const storageList = ["/gas-station/gas-create"];
  const apiData = onExportKey();
  // 缓存值
  if (storageList.includes(route.path)) {
    localStorage.setItem(route.path, JSON.stringify(apiData));
  }
}

// 取消按钮是否展示
function isCancel(list) {
  const is = list.some((item) => item.is);
  return is;
}

// 高德地图弹框
function onOpenAMap(item1: any, item2: any) {
  state.AMapData1 = item1;
  state.AMapData2 = item2;
  state.isAMap = true;
}

// 图片文件处理
function onUploadSuccess(value: any, data: any) {
  data.value = value;

  onChange("uploadimage", {}, data);
}

// radio数据处理
function onRadioText(value: any, list: any) {
  const arr = list.filter((item) => item.value === value);
  if (arr.length > 0) {
    return arr[0];
  }
  return "";
}

// 双日期处理
function onDatepicker(arr, type) {
  if (arr.length > 0) {
    switch (type) {
      case 2:
        return `${globalGetdate(arr[0]).long} 至 ${globalGetdate(arr[1]).long}`;
        break;
      case 3:
        return `${arr[0]} 至 ${arr[1]}`;
        break;

      default:
        return `${globalGetdate(arr[0]).date} 至 ${globalGetdate(arr[1]).date}`;
        break;
    }
  }
  return "";
}

function onAMapSuccess(data: any) {
  if (data.name) {
    // 复杂
    const { name, name2, tel, adcodeArray, address } = state.AMapData2.other;
    state.AMapData1.body_config.forEach((item) => {
      if (item.key_name == name) {
        item.value = item.value || data.name;
      }
      if (item.key_name == name2) {
        item.value = item.value || data.name2;
      }
      if (item.key_name == tel) {
        item.value = item.value || data.tel;
      }
      if (item.key_name == adcodeArray) {
        item.value = item.value[0] ? item.value : data.adcodeArray;
      }
      if (item.key_name == address) {
        item.value = item.value || data.address;
      }
      state.AMapData2.value = data.lnglatString;
    });
  } else {
    // 简单
    state.AMapData2.value = data.lnglatString;
  }
  onChange("lonlat", state.AMapData1, state.AMapData2);
}

// 地理位置
function onCity(arr = []) {
  if (state.options.length <= 0) return;
  const list = globalGetAddressAreas(arr, state.options);
  return list.join("-");
}

// 位置多级
function onCityMore(arr = []) {
  if (state.options.length <= 0) return;
  const list = arr.map((arr2: any) => {
    arr2 = globalGetAddressAreas(arr2, state.options);
    return arr2.join("-");
  });
  return list.join(", ");
}

// 多文字单选框二级数据处理
function onRadiolabelText(value, list) {
  const arr = list.filter((item) => item.value === value);
  if (arr.length > 0) {
    return arr[0];
  }
  return {
    value2: "",
  };
}

function isModuleShow(item1, data: any, type) {
  let is = false;
  is = data.type === type;
  if (data.type === type && data.event_key) {
    const len = item1.body_config.length;
    for (let i = 0; i < len; i++) {
      const item2 = item1.body_config[i];
      if (item2.key_name === data.event_key) {
        is = item2.value === data.event_value;
        break;
      }
    }
  }
  return is;
}

// 单选框切换检测，重置显示框的内容
function onRadioChange(item1, item2) {
  item1.body_config.forEach((item) => {
    if (item2.fun_key && item2.fun_key == item.key_name) {
      if (item.select_list.length > 0) {
        item.value = item.select_list[0].value;
      } else {
        item.value = "";
      }
    }
  });
  onChange("radios", item1, item2);
}

// input只能填写数字处理
function onInput(data: any) {
  if (data.comType == "number") {
    onInputFormat(data, "value", 1);
  } else if (data.comType == "point") {
    if (data.comMax) {
      onInputFormat(data, "value", 2, { max: data.comMax });
    } else {
      onInputFormat(data, "value", 2);
    }
  } else if (data.comType == "phone") {
    onInputFormat(data, "value", 15);
  } else if (data.comType == "en-num") {
    onInputFormat(data, "value", 3);
  }
}

// 返回
function onBack() {
  router.go(-1);
}

function isDisabledDate(label: any) {
  const list = ["*生效时间", "*指定时间"];
  return list.includes(label);
}

const disabledDate = (time: Date) => {
  return time.getTime() < Date.now() - 86400000;
};

// 根据key_name输出最终格式，默认执行，提交数据传入制定type
function onExportKey(type = 1) {
  const config = state.config;
  const data = {};
  config.forEach((item) => {
    data[item.head_config.key_name] = type;
    item.body_config.forEach((item2) => {
      //  && !data[item2.key_name]
      // 允许重复字段出现
      if (item2.key_name) {
        // 只有一个值的时候
        if (!item2.key_name2) {
          if (item2.type === "select") {
            // if()
            // data[item2.key_name] = item2.value;
            // 处理有显隐导致的重复字段问题
            if (item2.event_key) {
              if (data[item2.event_key] === item2.event_value) {
                // 显示的字段才有用
                data[item2.key_name] = item2.value;
              }
            } else {
              data[item2.key_name] = item2.value;
            }
          } else {
            data[item2.key_name] = item2.value;
          }
        }
        // select+input 多选框处理
        if (item2.type === "selectlabel") {
          data[item2.key_name] = item2.value;
          if (data[item2.key_name2]) {
            data[item2.key_name2] = item2.selectValue;
          }
        }
        // 多文字单选框二级处理
        if (item2.type === "radiolabel") {
          const obj = onRadiolabelText(item2.value, item2.radiolabel_list);
          if (obj.value2 === 0) {
            data[obj.key_name] = obj.value2;
          } else {
            data[obj.key_name] = obj.value2 || "";
          }
        }
        // 双日期选择器 双时间选择器
        if (double_argument_config.includes(item2.type)) {
          item2.value = item2.value || ["", ""];
          data[item2.key_name] = item2.value[0] || "";
          data[item2.key_name2] = item2.value[1] || "";
        }
        // 多图片和多文件上传
        if (
          item2.type === "uploadfilemore" ||
          item2.type === "uploadimagemore"
        ) {
          data[item2.key_name] = item2.value.join(",") || "";
        }
        // 三级城市选择器
        if (item2.type === "city") {
          if (state.options.length <= 0) return;
          data[item2.key_name] = item2.value[0] || "";
          data[item2.key_name2] = item2.value[1] || "";
          data[item2.key_name3] = item2.value[2] || "";
          const arrText = globalGetAddressAreas(item2.value, state.options);
          if (item2.key_name4) {
            data[item2.key_name4] = arrText[0];
          }
          if (data[item2.key_name5]) {
            data[item2.key_name5] = arrText[1];
          }
          if (data[item2.key_name6]) {
            data[item2.key_name6] = arrText[2];
          }
        }
        // 经纬度
        if (item2.type === "lonlat") {
          const arr = item2.value.split(",");
          data[item2.key_name] = String(arr[0]).trim() || "";
          data[item2.key_name2] = String(arr[1]).trim() || "";
        }
      }
    });
  });
  for (let item in data) {
    data[item] = data[item] == "undefined" ? "" : data[item];
  }
  state.api_data = data;
  return data;
}

// 数据类型统一处理
function onDataFormat(config) {
  const list = config.map((item) => {
    if (item.head_config.type) {
      switch (item.head_config.type) {
        case 2:
          item.body_config.forEach((item2) => (item2.islook = false));
          break;
        case 3:
          // 重置
          item.body_config.forEach((item2) => (item2.islook = true));
          // 执行对应按钮的权限
          item = onType3Format2(item);
          break;

        default:
          item.body_config.forEach((item2) => (item2.islook = true));
          break;
      }
    }
    return item;
  });
  return list;
}

// 不用引用类型的方式改变数据，引用改变会导致 props.config 和 state.config出现相互引用，导致watch props死循环
function onType3Format2(item: any) {
  const list = JSON.parse(JSON.stringify(item));
  const btn_array = list.head_config.btn_array.filter((item) => item.is);
  if (btn_array.length > 0) {
    const { isAuth, auth_list } = btn_array[0];
    if (isAuth) {
      list.body_config.forEach((item2) => (item2.islook = false));
    } else {
      list.body_config.forEach((item2) => {
        const is = auth_list.includes(item2.key_name);
        if (is) {
          item2.islook = false;
        }
      });
    }
  }
  return list;
}

function watchInit(newValue, isCancel = false) {
  // 统一处理 page_type
  let config_value = JSON.parse(JSON.stringify(newValue));
  // let config_value = Object.create(newValue);
  let list = config_value.config;
  list.forEach((item) => {
    if (config_value.page_type >= 1 && config_value.page_type <= 3) {
      item.head_config.type = props.page_type;
    }
  });
  // apply_type
  // if (config_value.type_list.includes(config_value.type)) {
  //   list.forEach((item) => {
  //     const list = item.head_config.btn_array.filter(
  //       (item) => item.value === config_value.type
  //     );
  //     if (list.length <= 0) {
  //       item.head_config.btn_array = [];
  //     }
  //   });
  // }
  // 编辑默认值 type 处理
  if (!isCancel) {
    list.forEach((item) => {
      item.head_config.btn_array.forEach((item2) => {
        if (item2.value === config_value.type) {
          item2.is = true;
        } else {
          item2.is = false;
        }
      });
    });
  }
  // 编辑模式只允许有一个card可编辑
  // const applyTypes = [2, 3, "printer_1", "printer_2", 4];
  // && applyTypes.includes(Number(apply_type))
  // gas-station/gas-edit
  if (config_value.page_type == 3) {
    if (list.length > 0) {
      if (route.path == "/gas-station/gas-edit") {
        // 加油站定制
        switch (Number(apply_type)) {
          case 2:
            // 修改基本信息
            list[0].head_config.btn_array.splice(0, 1);
            list[1].head_config.btn_array = [];
            list[2].head_config.btn_array = [];
            if (!isCancel) {
              // 取消不改变模式
              list[0].head_config.btn_array[0].is = true;
            }
            break;
          case 3:
            // 换证
            list[0].head_config.btn_array.splice(1, 1);
            list[1].head_config.btn_array = [];
            list[2].head_config.btn_array = [];
            if (!isCancel) {
              // 取消不改变模式
              list[0].head_config.btn_array[0].is = true;
            }
            break;
          case 4:
            // 账户信息
            list[0].head_config.btn_array = [];
            list[1].head_config.btn_array = [];
            if (!isCancel) {
              // 取消不改变模式
              list[2].head_config.btn_array[0].is = true;
            }
            break;
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
            // 结算及优惠信息、合同等
            list[0].head_config.btn_array = [];
            list[1].head_config.btn_array = [];
            list[2].head_config.btn_array = [];
            break;

          default:
            break;
        }
      } else {
        // 非加油站，检测选中项是否在item项中，不存在的清楚操作按钮设置
        if (apply_type) {
          list.forEach((item) => {
            const list = item.head_config.btn_array.filter(
              (item) => item.value === config_value.type
            );
            if (list.length <= 0) {
              item.head_config.btn_array = [];
            }
          });
        }
      }
    }
  }
  // 模块独立显隐全局控制处理
  list = onDataFormat(list);
  // slot类型处理
  list = list.filter((item) => {
    let is = true;
    if (item.type) {
      is = item.show_typs.includes(item.head_config.type);
    }
    return is;
  });
  // api重置配置文件值
  if (config_value.base_api) {
    list.forEach((item) => {
      item.body_config.forEach((item2) => {
        if (!item2.key_name2) {
          item2.value =
            config_value.base_api && config_value.base_api[item2.key_name];
        }
        // select+input 多选框处理
        if (item2.type === "selectlabel") {
          item2.value =
            config_value.base_api && config_value.base_api[item2.key_name];
          item2.selectValue =
            config_value.base_api && config_value.base_api[item2.key_name2];
        }
        // 多文字单选框二级处理
        if (item2.type === "radiolabel") {
          item2.radiolabel_list.forEach((item3) => {
            item3.value2 =
              config_value.base_api && config_value.base_api[item3.key_name];
          });
        }
        // 双日期选择器 双时间选择器
        if (double_argument_config.includes(item2.type)) {
          item2.value = [
            config_value.base_api && config_value.base_api[item2.key_name],
            config_value.base_api && config_value.base_api[item2.key_name2],
          ];
        }
        // 多图片和多文件上传
        if (
          item2.type === "uploadfilemore" ||
          item2.type === "uploadimagemore"
        ) {
          const moreStr =
            config_value.base_api &&
            String(config_value.base_api[item2.key_name] || "");
          if (moreStr.trim() == "") {
            item2.value = [];
          } else {
            item2.value = moreStr.split(",");
          }
        }
        // 三级城市选择器
        if (item2.type === "city") {
          item2.value = [
            String(
              config_value.base_api && config_value.base_api[item2.key_name]
            ),
            String(
              config_value.base_api && config_value.base_api[item2.key_name2]
            ),
            String(
              config_value.base_api && config_value.base_api[item2.key_name3]
            ),
          ];
        }
        // 经纬度
        if (item2.type === "lonlat") {
          const lon =
            config_value.base_api && config_value.base_api[item2.key_name];
          const lat =
            config_value.base_api && config_value.base_api[item2.key_name2];
          if (String(lon).trim() != "" && String(lat).trim() != "") {
            item2.value = `${String(lon).trim()}, ${String(lat).trim()}`;
          } else {
            item2.value = "";
          }
        }
      });
    });
  }

  state.config = list;
}

watch(
  () => props,
  (newValue) => {
    // newValue值不允许二次赋值，以后操作的时候，需要重新建立数值空间
    watchInit(newValue);
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => state.config,
  () => {
    onExportKey();
  },
  {
    immediate: true,
    deep: true,
  }
);

defineExpose({
  config: computed(() => state.config),
  api_data: computed(() => state.api_data),
});
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
