import Layout from "@/layout";

const channel_manage_router_config = [
  {
    path: "/channel-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/channel-manage/channel-look",
        component: () => import("@/views/channel-manage/create/index.vue"),
        hidden: true,
        meta: { title: "渠道查看", icon: "look" },
      },
      {
        path: "/channel-manage/channel-create-edit",
        component: () => import("@/views/channel-manage/create/index.vue"),
        hidden: true,
        meta: { title: "渠道创建编辑", icon: "look" },
      },
      {
        path: "/channel-manage/channel-edit",
        component: () => import("@/views/channel-manage/create/index.vue"),
        hidden: true,
        meta: { title: "渠道编辑", icon: "edit" },
      },
      {
        path: "/my-review/channel-audit",
        component: () => import("@/views/channel-manage/audit/index.vue"),
        hidden: true,
        meta: { title: "渠道审核", icon: "list" },
      },
      // {
      //   path: "/channel-manage/channel-money-rule",
      //   component: () => import("@/views/channel-manage/money-rule/index.vue"),
      //   hidden: true,
      //   meta: { title: "渠道佣金规则", icon: "edit" },
      // },
      // {
      //   path: "/channel-manage/channel-driver-rule",
      //   component: () => import("@/views/channel-manage/driver-rule/index.vue"),
      //   hidden: true,
      //   meta: { title: "渠道司机服务费规则", icon: "edit" },
      // },
      // {
      //   path: "/channel-manage/channel-account-list",
      //   component: () =>
      //     import("@/views/channel-manage/account/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "对公账户列表", icon: "edit" },
      // },
      {
        path: "/channel-manage/channel-account-create",
        component: () =>
          import("@/views/channel-manage/account/create/index.vue"),
        hidden: true,
        meta: { title: "渠道对公账户详情", icon: "edit" },
      },
      // {
      //   path: "/channel-manage/channel-account-apply",
      //   component: () =>
      //     import("@/views/channel-manage/account/apply/index.vue"),
      //   hidden: true,
      //   meta: { title: "渠道对公账户修改申请列表", icon: "edit" },
      // },
      {
        path: "/channel-manage/channel-account-records",
        component: () =>
          import("@/views/channel-manage/account/records/index.vue"),
        hidden: true,
        meta: { title: "渠道对公账户申请操作记录", icon: "edit" },
      },
      // {
      //   path: "/channel-manage/channel-account-review",
      //   component: () =>
      //     import("@/views/channel-manage/account/review/index.vue"),
      //   hidden: true,
      //   meta: { title: "渠道对公账户审核列表", icon: "edit" },
      // },
      {
        path: "/channel-manage/channel-account-audit",
        component: () =>
          import("@/views/channel-manage/account/audit/index.vue"),
        hidden: true,
        meta: { title: "渠道对公账户审核", icon: "edit" },
      },
      // {
      //   path: "/channel-manage/channel-advance-money",
      //   component: () =>
      //     import("@/views/channel-manage/advance-money/index.vue"),
      //   hidden: true,
      //   meta: { title: "渠道垫资规则", icon: "edit" },
      // },
    ],
  },
];

export default channel_manage_router_config;
