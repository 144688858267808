import request from "@/utils/request";

// 获取油站编码
export function getCode() {
  return request({
    url: "gasStation/apply/getCode",
    method: "get",
  });
}
// 油站创建提交
export function applyAdd(data) {
  return request({
    url: "gasStation/apply/add",
    method: "post",
    data: data,
  });
}
// 油站创建保存待提交
export function applySave(data) {
  return request({
    url: "gasStation/apply/save",
    method: "post",
    data: data,
  });
}
// 设备校验
export function checkPrinter(data) {
  return request({
    url: "printer/management/checkPrinter",
    method: "post",
    data: data,
  });
}

// 加油站列表
export function getManagementList(data, params) {
  return request({
    url: "stationInfo/management/list",
    method: "post",
    data,
    params: params,
  });
}

// 我的申请列表
export function getAuditApplyList(data, params) {
  return request({
    url: "station/audit/applyList",
    method: "post",
    data,
    params: params,
  });
}
// 我的申请列表-详情
export function getAuditDetail(id) {
  return request({
    url: `station/audit/query/${id}`,
    method: "get",
  });
}

// 加油站管理修改站点
export function managementEdit(data) {
  return request({
    url: "stationInfo/management/edit",
    method: "post",
    data: data,
  });
}

// 加油站管理-详情
export function getManagementDetail(id) {
  return request({
    url: `stationInfo/management/query/${id}`,
    method: "get",
  });
}

// 审核列表
export function auditList(data, params) {
  return request({
    url: "station/audit/list",
    method: "post",
    data,
    params: params,
  });
}

// 进行审核
export function auditAudit(data) {
  return request({
    url: "station/audit/audit",
    method: "post",
    data: data,
  });
}

// 启用站点
export function managementEnable(id) {
  return request({
    url: `stationInfo/management/enable/${id}`,
    method: "get",
  });
}

// 禁用站点
export function managementUnable(id) {
  return request({
    url: `stationInfo/management/unable/${id}`,
    method: "get",
  });
}

// 待审核撤销
export function auditRevocation(id) {
  return request({
    url: `station/audit/revocation/${id}`,
    method: "get",
  });
}

// 审核提交
export function auditEdit(data) {
  return request({
    url: "station/audit/edit",
    method: "post",
    data: data,
  });
}

// 油枪列表
export function oilGunList(data, params) {
  return request({
    url: "oilStation/oilGun/list",
    method: "post",
    data: data,
    params,
  });
}

// 油枪记录
export function oilGunRecord(id) {
  return request({
    url: `oilStation/oilGun/oilGunRecord/${id}`,
    method: "get",
  });
}

// 油价列表
export function oilGunPriceList(data, params) {
  return request({
    // url: "oilStation/oilGunPrice/list",
    url: "oil/gunprice/listGasGunPrice",
    method: "post",
    data: data,
    params,
  });
}

// 油价记录
export function oilGunPriceRecord(data, params) {
  return request({
    url: "oil/gunprice/listHistoryPriceChange",
    method: "post",
    data: data,
    params,
  });
}

// 解绑打印机
export function unBindPrinter(sn) {
  return request({
    url: `printer/management/unBindPrinter/${sn}`,
    method: "get",
  });
}

// 更换打印机
export function updateStationPrinter(data) {
  return request({
    url: "printer/management/updateStationPrinter",
    method: "post",
    data: data,
  });
}

// 服务商下拉展示
export function managementGetList() {
  return request({
    url: `facilitator/management/getList`,
    method: "get",
  });
}

// 结算及优惠管理 详情
export function settlementConfigQuery(gasId) {
  return request({
    url: `settlementConfig/query/${gasId}`,
    method: "get",
  });
}

// 结算及优惠管理 申请和审核详情
export function settlementConfigApplyQuery(id) {
  return request({
    url: `settlementConfig/Apply/query/${id}`,
    method: "get",
  });
}

// 创建-修改结算方式
export function settlementConfigSave(data) {
  return request({
    url: "settlementConfig/save",
    method: "post",
    data: data,
  });
}

// 修改审核
export function settlementApplyEdit(data) {
  return request({
    url: "settlementConfig/Apply/edit",
    method: "post",
    data: data,
  });
}

// 结算及优惠信息 我的申请列表
export function settlementConfigApplyMyList(data, params) {
  return request({
    url: "settlementConfig/Apply/myList",
    method: "post",
    data,
    params,
  });
}

// 结算及优惠信息 撤回
export function settlementConfigApplyRevocation(id) {
  return request({
    url: `settlementConfig/Apply/revocation/${id}`,
    method: "get",
  });
}

// 结算及优惠信息 审核列表
export function settlementConfigApplyList(data, params) {
  return request({
    url: "settlementConfig/Apply/list",
    method: "post",
    data,
    params,
  });
}

// 结算及优惠信息 审核
export function settlementApplyAudit(data) {
  return request({
    url: "settlementConfig/Apply/audit",
    method: "post",
    data: data,
  });
}

// 结算及优惠信息 获取审核操作记录
export function settlementConfigRecordList(id) {
  return request({
    url: `settlementConfig/record/list/${id}`,
    method: "get",
  });
}

// 根据纳税人识别号和商户编号获取对公账户信息
export function getByTaxpayerNum(data) {
  return request({
    url: "corporateAccount/getByTaxpayerNum",
    method: "post",
    data,
  });
}

// 获取商户信息根据吗名称
export function getMerchantInfoByName(merchantName) {
  return request({
    url: `merchant/getMerchantInfoByName/${merchantName}`,
    method: "get",
  });
}

// 根据商户查询合同信息
export function getContractInfo(merchantNo) {
  return request({
    url: `contract/getContractInfo/${merchantNo}`,
    method: "get",
  });
}

// 油站发布检查 列表
export function stationCheckList(data, params) {
  return request({
    url: "publish/station/checkList",
    method: "post",
    data,
    params,
  });
}

// 查询业务经理
export function queryBusinessManager(userName) {
  return request({
    url: `system/user/queryBusinessManager/${userName}`,
    method: "get",
  });
}

// 变更业务经理
export function changeBusinessManager(data) {
  return request({
    url: "stationInfo/management/changeBusinessManager",
    method: "post",
    data,
  });
}

// 业务经理变更记录列表
export function businessManagerUpdateLogList(gasId, params) {
  return request({
    url: `businessManagerUpdateLog/businessManagerUpdateLogList/${gasId}`,
    method: "get",
    params,
  });
}

// 查询油站对账时间配置表
export function stationStatementTime(gasId) {
  return request({
    url: `stationStatementTime/query/${gasId}`,
    method: "get",
  });
}

// 保存油站对账时间配置表
export function stationStatementTimeSave(data) {
  return request({
    url: "stationStatementTime/save",
    method: "post",
    data,
  });
}

// 查询油站打印配置
export function printOrderConfig(gasId) {
  return request({
    url: `printOrderConfig/query/${gasId}`,
    method: "get",
  });
}

// 修改油站打印配置
export function printOrderConfigEdit(data) {
  return request({
    url: "printOrderConfig/edit",
    method: "post",
    data,
  });
}

// 加油站-溢价信息详情(列表)
export function stationQueryPublish(data, params) {
  return request({
    url: `publish/station/queryPublish`,
    method: "post",
    data,
    params,
  });
}
// 供应商列表
export function enumSupplierList() {
  return request({
    url: `enum/supplier/list`,
    method: "post",
  });
}
