// 营销管理-活动管理
import request from "@/utils/request";

// 活动列表
export function activityList(data, params) {
  return request({
    url: "activity/list",
    method: "post",
    data,
    params,
  });
}
// 活动启用-停用
export function activityEnable(data) {
  return request({
    url: "activity/enable",
    method: "post",
    data,
  });
}

// 已发布活动列表
export function activityPublishList(data, params) {
  return request({
    url: "activity/publish/list",
    method: "post",
    data,
    params,
  });
}

// 创建活动
export function activitySave(data) {
  return request({
    url: "activity/save",
    method: "post",
    data,
  });
}

// 活动管理-活动详情
export function activityInfo(activityNo) {
  return request({
    url: `activity/info`,
    method: "get",
    params: {
      activityNo,
    },
  });
}

// 活动申请管理-活动详情
export function activityApplyInfo(id) {
  return request({
    url: `activity/apply/info`,
    method: "get",
    params: {
      id,
    },
  });
}

// 活动申请列表
export function activityApplyList(data, params) {
  return request({
    url: "activity/apply/list",
    method: "post",
    data,
    params,
  });
}
// 活动审核
export function activityApplyAudit(data) {
  return request({
    url: "activity/apply/audit",
    method: "post",
    data,
  });
}

// 活动审核
export function activityApplyAuditList(data, params) {
  return request({
    url: "activity/apply/audit/list",
    method: "post",
    data,
    params,
  });
}

// 活动申请管理-活动审核操作记录列表
export function activityApplyLogList(id) {
  return request({
    url: `activity/apply/log/list`,
    method: "get",
    params: {
      id,
    },
  });
}

// 活动编辑
export function activityApplyUpdate(data) {
  return request({
    url: "activity/apply/update",
    method: "post",
    data,
  });
}

// 活动撤回
export function activityApplyWithdraw(id) {
  return request({
    url: `activity/apply/withdraw`,
    method: "get",
    params: {
      id,
    },
  });
}

// 活动失效
export function activityInvalid(publishNo) {
  return request({
    url: `activity/publish/invalid`,
    method: "get",
    params: {
      publishNo,
    },
  });
}

// 发布活动详情
export function activityPublishInfo(publishNo) {
  return request({
    url: `activity/publish/info`,
    method: "get",
    params: {
      publishNo,
    },
  });
}

// 发布筛选活动 -活动类型和活动编号搜索
export function activityChoice(params) {
  return request({
    url: `activity/choice`,
    method: "get",
    params,
  });
}

// 活动查询关联加油站列表
export function managementContactList(data, params) {
  return request({
    url: "stationInfo/management/contact/list",
    method: "post",
    data,
    params,
  });
}

// 叠加-互斥活动列表
export function activityDeployList(data) {
  return request({
    url: `activity/publish/deploy/list`,
    method: "post",
    data,
  });
}
// 发布活动
export function activityPublishSave(data) {
  return request({
    url: "activity/publish/save",
    method: "post",
    data,
  });
}

// 发布活动关联的加油站列表
export function activityPublishDeployList(params) {
  return request({
    url: `activity/publish/station/list`,
    method: "get",
    params,
  });
}

// 发布活动申请关联加油站列表
export function activityApplyPublishDeployList(params) {
  return request({
    url: `activity/apply/publish/station/list`,
    method: "get",
    params,
  });
}

// 修改发布活动
export function activityPublishUpdate(data) {
  return request({
    url: "activity/publish/update",
    method: "post",
    data,
  });
}

// 油站活动查询
export function activityPublishStationQuery(data, params) {
  return request({
    url: "activity/publish/station/query",
    method: "post",
    data,
    params,
  });
}

// 发布活动统计
export function activityPublishStatistics(params) {
  return request({
    url: `activity/publish/statistics`,
    method: "get",
    params,
  });
}

// 区间活动标签描述
export function intervalLabelContent(activityNo) {
  return request({
    url: `activity/publish/interval/label/content`,
    method: "get",
    params: {
      activityNo,
    },
  });
}

// 区间活动首页标签描述
export function labelHomeContent(activityNo) {
  return request({
    url: `activity/publish/interval/label/home/content`,
    method: "get",
    params: {
      activityNo,
    },
  });
}
// 检查区间活动油站阈值后折扣配置
export function intervalCheck(data) {
  return request({
    url: "activity/publish/interval/check",
    method: "post",
    data,
  });
}

// 下载模版
export function downTemplate() {
  const url = `${
    import.meta.env.VITE_IMG_UPLOAD_API
  }/template/download/37e55293cb114d41b697fdb714c6a303.xlsx`;
  return url;
}

// 批量导入
export function managementImport(data) {
  return request({
    url: "stationInfo/management/import",
    method: "post",
    data,
  });
}
// 油站活动变更记录列表
export function recordList(data, params) {
  return request({
    url: "activity/publish/station/record/list",
    method: "post",
    data,
    params,
  });
}
// 结束活动
export function activityPublishStationEnd(data) {
  return request({
    url: "activity/publish/station/end",
    method: "post",
    data,
  });
}
