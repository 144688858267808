<template>
  <div class="page_box">
    <div id="amap_box"></div>
    <div id="form_box">
      <div class="form_main">
        <div class="form_select">
          <el-select
            v-model="state.city"
            placeholder="请选择"
            popper-class="form_popper"
            filterable
            @change="onSelectChange"
          >
            <el-option
              v-for="item in cityListConfig"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="form_input">
          <el-input
            v-model.trim="state.keyTest"
            id="form_input"
            placeholder="请输入"
            @keydown.enter="onSearch"
          />
        </div>
        <el-button type="primary" auto-insert-space @click="onSearch"
          >搜索</el-button
        >
      </div>

      <div
        class="form_info"
        v-show="state.data.longitude && state.data.latitude"
      >
        {{ state.data.longitude }}, {{ state.data.latitude }}
      </div>
      <div class="form_btn">
        <el-button type="primary" auto-insert-space @click="onOk"
          >确定</el-button
        >
        <el-button type="danger" plain auto-insert-space @click="onShut"
          >关闭</el-button
        >
      </div>
    </div>
    <!-- 内容弹框 -->
    <div class="panel" id="panel" v-show="state.isPanel"></div>
    <div class="panel_shut" v-show="state.isPanel !== ''">
      <el-button type="danger" @click="state.isPanel = !state.isPanel">{{
        state.isPanel ? "隐藏" : "显示"
      }}</el-button>
    </div>
    <!-- 确认弹框 -->
    <el-dialog v-model.trim="state.isInfo" title="信息确认" :z-index="9999">
      <el-form-item label="油站名称" label-width="100">
        <el-input v-model.trim="state.data.name" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="油站简称" label-width="100">
        <el-input v-model.trim="state.data.name2" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="油站电话" label-width="100">
        <el-input v-model.trim="state.data.tel" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="油站位置" label-width="100">
        <el-cascader
          v-model.trim="state.data.adcodeArray"
          :options="state.options"
          clearable
          style="width: 100%"
          placeholder="请选择"
          :z-index="9999"
        />
      </el-form-item>
      <el-form-item label="详细地址" label-width="100">
        <el-input v-model.trim="state.data.address" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="经纬度" label-width="100">{{
        state.data.lnglatString
      }}</el-form-item>
      <div class="form_tip">
        注意：经纬度之外的字段，已经填写内容的字段，内容不会被替换。
      </div>
      <template #footer>
        <el-button @click="state.isInfo = false">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script setup lang="ts">
import { onMounted, reactive } from "vue";
import { ElMessage } from "element-plus";
import AMapLoader from "@amap/amap-jsapi-loader";
import { cityListConfig } from "./config.js";
import { globalAddressAreas } from "@/utils/date_tools.js";

// 开发环境：配置JsCode
// 如果是在 ts 的项目中，这一步会有类型错误，解决方案请移步 2.1.1

const emits = defineEmits(["success", "shut"]);
const props = defineProps({
  province: {
    type: String,
    default: "",
  },
  center: {
    type: String,
    default() {
      return "";
    },
  },
});

let { value: city, center }: any = cityListConfig[0];
const localAmapData = JSON.parse(localStorage.getItem("amap_city") || "{}");
if (localAmapData.value) {
  city = localAmapData.value;
  center = localAmapData.center;
}
// 默认值
if (props.province) {
  city = props.province;
}
if (props.center) {
  center = props.center.split(",");
}
const state: any = reactive({
  input: "",
  map: null,
  AMap: null,
  city,
  center: center,
  keyTest: "",
  isPanel: "",
  data: {
    name: "", // 名称
    name2: "", // 简称
    adcode: "", // 三级code码
    adcodeArray: ["", "", ""], // 三级码数组格式
    address: "", // 地址
    tel: "", // 电话
    longitude: "", // 经度
    latitude: "", // 纬度
    lnglatString: "", // 经纬度字符串
  },
  isInfo: false,
  options: [],
});

function onSelectChange(val: any) {
  const data = cityListConfig.filter((item: any) => item.value == val)[0];
  const { center } = data;
  state.center = center;
  state.map.setCenter(center);
  localStorage.setItem("amap_city", JSON.stringify(data));
}

function onSearch() {
  if (!state.keyTest) {
    ElMessage.error("搜索内容不能为空!");
    return false;
  }
  state.AMap.plugin(["AMap.PlaceSearch"], function () {
    var PlaceSearchOptions = {
      //设置PlaceSearch属性
      city: state.city, // 兴趣点城市
      citylimit: true, //是否强制限制在设置的城市内搜索
      type: "", // 数据类别
      pageSize: 6, //每页结果数,默认10
      pageIndex: 1, //请求页码，默认1
      extensions: "base", //返回信息详略，默认为base（基本信息）
      map: state.map, // 展现结果的地图实例
      panel: "panel", // 结果列表将在此容器中进行展示
      autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
    };
    var MSearch = new state.AMap.PlaceSearch(PlaceSearchOptions); //构造PlaceSearch类
    MSearch.search(state.keyTest, (type: any, data: any) => {
      if (type == "error") {
        ElMessage.error(`高德地图异常，错误码 [${data}]`);
        return false;
      }
      state.isPanel = true;
    }); //关键字查询
    MSearch.on("selectChanged", (e: any) => {
      onDataHandle(e.selected);
    });
    MSearch.on("markerClick", (e: any) => {
      // 名称 三级code码 地址 电话 经纬度集合
      onDataHandle(e);
    });
  });
}

function onDataHandle(e: any) {
  const { name, name2, adcode, address, tel, location } = e.data;
  const { lng: longitude, lat: latitude } = location;
  const data = {
    name,
    name2: name,
    adcodeArray: [adcode.slice(0, 2), adcode.slice(0, 4), adcode],
    adcode,
    address,
    tel,
    longitude,
    latitude,
    lnglatString: `${longitude}, ${latitude}`,
  };
  state.data = data;
}

/** 初始化地图函数 */
onMounted(async () => {
  const options = await globalAddressAreas();
  state.options = options;
  // 地图
  window._AMapSecurityConfig = {
    securityJsCode: "9c13576e120fea687d8f8c595c6305a2",
  };
  // AMapLoader => 加载器
  // 资源加载完成后就会触发 then
  AMapLoader.load({
    key: "1ee39d86ddbf84e9f06422644246708c", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0", // 指定要加载的 JS API 的版本，缺省时默认为 1.4.15
    plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
  })
    .then((AMap) => {
      // 初始化地图
      const map = new AMap.Map("amap_box", {
        // 配置对象 - 配置地图的风格和缩放比例，请移步 2.2
        center: state.center,
        zoom: 19,
        // 关闭实时路况信息
        showTraffic: false,
        animateEnable: false,
      });
      // 点击地图获取经纬度
      map.on("click", function (e: any) {
        const longitude = e.lnglat.lng;
        const latitude = e.lnglat.lat;
        const data = {
          name: "",
          name2: "",
          adcode: "",
          address: "",
          tel: "",
          adcodeArray: ["", "", ""],
          longitude,
          latitude,
          lnglatString: `${longitude}, ${latitude}`,
        };
        state.data = data;
      });
      state.map = map;
      state.AMap = AMap;

      const markerCenter = new AMap.Marker({
        position: state.center,
        zIndex: 999,
      });
      map.add(markerCenter);
    })
    .catch((e) => {
      console.log(e);
    });
});

function onSubmit() {
  const { longitude, latitude } = state.data;
  if (!(longitude && latitude)) {
    ElMessage.error("经纬度不能为空!");
    return false;
  }
  emits("success", state.data);
  onShut();
}
function onShut() {
  emits("shut");
}
function onOk() {
  if (state.data.name) {
    state.isInfo = true;
  } else {
    onSubmit();
  }
}
</script>

<style scoped lang="scss">
@import "./style.scss";
</style>
<style>
.amap-sug-result,
.form_popper,
.el-message {
  z-index: 9999 !important;
}
</style>
