const useToolStore = defineStore("tool", {
  state: () => ({
    storeAddressAreas: [],
    storeDict: {},
    storeTool: {
      mainBankList: [], // 总行
      businessManagerList: [], // 业务经理
    },
  }),
  actions: {
    setStoreAddressAreas(list) {
      this.storeAddressAreas = list;
    },
    setDict(key, list) {
      this.storeDict[key] = list;
    },
    setTool(key, list) {
      this.storeTool[key] = list;
    },
  },
});

export default useToolStore;
