<template>
  <div class="page_box">
    <el-card>
      <template #header>
        <div class="card_box">
          <div class="card_box">
            <div class="card_header">{{ props.title }}</div>
          </div>
          <div></div>
        </div>
      </template>
      <el-row
        :gutter="20"
        class="box_border"
        v-for="(item, index) in props.list"
        :key="index"
      >
        <el-col :span="24">
          <el-form-item
            label="服务商名称："
            class="border_one"
            label-width="100"
            >{{ item.facilitatorName }}</el-form-item
          >
        </el-col>
        <el-col :span="8">
          <el-form-item label="有效期：" label-width="100"
            >{{ item.contractStartDate }} 至
            {{ item.contractEndDate }}</el-form-item
          >
        </el-col>
        <el-col :span="8">
          <el-form-item label="合同编号：" label-width="100">{{
            item.contractNum
          }}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态：" label-width="100">{{
            item.effectStatusDesc
          }}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="主合同" :label-width="100">
            <PictureUploadComOnly
              accept=".pdf"
              :type="3"
              text="支持 PDF 格式，且不超过10MB"
              :img_key="item.contractFile"
              :islook="true"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="从属合同" :label-width="100">
            <PictureUploadComMultiple
              accept=".pdf"
              :type="3"
              text="支持 PDF 格式，且不超过10MB"
              :file_list="item.extraContractArr"
              :islook="true"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script setup lang="ts">
const props: any = defineProps({
  title: {
    type: String,
    default: "上传合同",
  },
  list: {
    type: Array,
    default: [],
  },
});
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
