import Layout from "@/layout";

const nation_manage_router_config = [
  // {
  //   path: "/nation-manage",
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: "/nation-manage/nation-list",
  //       component: () => import("@/views/nation-manage/list/index.vue"),
  //       hidden: true,
  //       meta: { title: "国标价列表", icon: "list" },
  //     },
  //   ],
  // },
  {
    path: "/nation-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/nation-manage/nation-look",
        component: () => import("@/views/nation-manage/create/index.vue"),
        hidden: true,
        meta: { title: "国标价详情", icon: "look" },
      },
      // {
      //   path: "/nation-manage/nation-link",
      //   component: () => import("@/views/nation-manage/link/index.vue"),
      //   hidden: true,
      //   meta: { title: "油站调价链接", icon: "look" },
      // },
      {
        path: "/nation-manage/nation-link-records",
        component: () => import("@/views/nation-manage/link-records/index.vue"),
        hidden: true,
        meta: { title: "未调价明细", icon: "look" },
      },
    ],
  },
  {
    path: "/nation-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/nation-manage/nation-edit",
        component: () => import("@/views/nation-manage/create/index.vue"),
        hidden: true,
        meta: { title: "修改国标价", icon: "edit" },
      },
    ],
  },
  {
    path: "/nation-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/nation-manage/nation-records",
        component: () => import("@/views/nation-manage/records/index.vue"),
        hidden: true,
        meta: { title: "国标价变更记录", icon: "edit" },
      },
    ],
  },
  {
    path: "/nation-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/nation-manage/nation-apply",
        component: () => import("@/views/nation-manage/apply/index.vue"),
        hidden: true,
        meta: { title: "国标价申请", icon: "list" },
      },
    ],
  },
  {
    path: "/nation-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/nation-manage/nation-review",
        component: () => import("@/views/nation-manage/review/index.vue"),
        hidden: true,
        meta: { title: "国标价审核列表", icon: "list" },
      },
    ],
  },
  {
    path: "/nation-manage",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/nation-manage/nation-audit",
        component: () => import("@/views/nation-manage/audit/index.vue"),
        hidden: true,
        meta: { title: "国标价审核", icon: "list" },
      },
    ],
  },
];
export default nation_manage_router_config;
