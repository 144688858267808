import Layout from "@/layout";

const gas_station_router_config = [
  {
    path: "/gas-station",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/gas-station/examine-edit",
        component: () => import("@/views/gas-station/examine-edit/index"),
        hidden: true,
        meta: { title: "审核列表", icon: "edit" },
      },
      {
        path: "/gas-station/oil-gun",
        component: () => import("@/views/gas-station/oil-gun/index"),
        hidden: true,
        meta: { title: "油枪信息", icon: "edit" },
      },
      {
        path: "/gas-station/oil-gun-records",
        component: () => import("@/views/gas-station/oil-gun-records/index"),
        hidden: true,
        meta: { title: "油枪信息记录", icon: "edit" },
      },
      {
        path: "/gas-station/oil-price",
        component: () => import("@/views/gas-station/oil-price/index"),
        hidden: true,
        meta: { title: "油枪价信息", icon: "edit" },
      },
      {
        path: "/gas-station/oil-price-records",
        component: () => import("@/views/gas-station/oil-price-records/index"),
        hidden: true,
        meta: { title: "油价信息记录", icon: "edit" },
      },
      {
        path: "/gas-station/account-detail",
        component: () => import("@/views/gas-station/accountDetail/index.vue"),
        hidden: true,
        meta: { title: "结算及优惠信息", icon: "edit" },
      },
      {
        path: "/gas-station/account-detail/audit",
        component: () => import("@/views/gas-station/accountAudit/index.vue"),
        hidden: true,
        meta: { title: "结算及优惠信息去审核", icon: "edit" },
      },
      {
        path: "/gas-station/account-detail/records",
        component: () => import("@/views/gas-station/accountRecords/index.vue"),
        hidden: true,
        meta: { title: "结算及优惠信息操作记录", icon: "edit" },
      },
      {
        path: "/gas-station/records-ywjl",
        component: () => import("@/views/gas-station/records/index.vue"),
        hidden: true,
        meta: { title: "业务经理修改记录", icon: "edit" },
      },
      {
        path: "/gas-station/station-price",
        component: () => import("@/views/gas-station/station-price/index.vue"),
        hidden: true,
        meta: { title: "渠道溢价信息", icon: "edit" },
      },
    ],
  },
];

export default gas_station_router_config;
