const custom_small_router_config = [
  {
    path: "/small-sale/list",
    component: () => import("@/viewsmall/sale/list/index.vue"),
    hidden: true,
    meta: { title: "油站列表", icon: "look" },
  },
  {
    path: "/small-sale/user",
    component: () => import("@/viewsmall/sale/user/index.vue"),
    hidden: true,
    meta: { title: "站联销售助手", icon: "look" },
  },
  {
    path: "/small-sale/config",
    component: () => import("@/viewsmall/sale/config/index.vue"),
    hidden: true,
    meta: { title: "看板配置", icon: "look" },
  },
  {
    path: "/small-sale/detail",
    component: () => import("@/viewsmall/sale/detail/index.vue"),
    hidden: true,
    meta: { title: "油站详情", icon: "look" },
  },
  {
    path: "/small-sale/statist-sum",
    component: () => import("@/viewsmall/sale/statist-sum/index.vue"),
    hidden: true,
    meta: { title: "汇总统计", icon: "look" },
  },
  {
    path: "/small-sale/statist-sum-detail",
    component: () => import("@/viewsmall/sale/statist-sum-detail/index.vue"),
    hidden: true,
    meta: { title: "汇总统计详情", icon: "look" },
  },
  {
    path: "/small-sale/statist-oil",
    component: () => import("@/viewsmall/sale/statist-oil/index.vue"),
    hidden: true,
    meta: { title: "油站统计", icon: "look" },
  },
  {
    path: "/small-sale/statist-oil-detail",
    component: () => import("@/viewsmall/sale/statist-oil-detail/index.vue"),
    hidden: true,
    meta: { title: "油站统计", icon: "look" },
  },
  {
    path: "/small-sale/rank-run",
    component: () => import("@/viewsmall/sale/rank-run/index.vue"),
    hidden: true,
    meta: { title: "流水排名", icon: "look" },
  },
  {
    path: "/small-sale/rank-sign",
    component: () => import("@/viewsmall/sale/rank-sign/index.vue"),
    hidden: true,
    meta: { title: "签约排名", icon: "look" },
  },
  {
    path: "/small-sale/oil-gun",
    component: () => import("@/viewsmall/sale/oil-gun/index.vue"),
    hidden: true,
    meta: { title: "修改油枪价", icon: "look" },
  },
  {
    path: "/small-sale/discounts",
    component: () => import("@/viewsmall/sale/discounts/index.vue"),
    hidden: true,
    meta: { title: "修改直降优惠", icon: "look" },
  },
];

export default custom_small_router_config;
