import Layout from "@/layout";

const supplier_manage_router_config = [
  {
    path: "/supplier-manage",
    component: Layout,
    hidden: true,
    children: [
      // {
      //   path: "/supplier-manage/supplier-list",
      //   component: () => import("@/views/supplier-manage/list/index.vue"),
      //   hidden: true,
      //   meta: { title: "供应商列表", icon: "list" },
      // },
    ],
  },
];
export default supplier_manage_router_config;
