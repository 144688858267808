<template>
  <div class="page_box page_header">
    <div class="table_box">
      <div class="table_item">序号</div>
      <div class="table_item">{{ props.tableTitleList[0].title }}</div>
      <div class="table_item">{{ props.tableTitleList[1].title }}</div>
      <div class="table_item table_page">
        <div class="table_box">
          <div class="table_item">{{ props.tableTitleList[2].title }}</div>
          <div class="table_item">{{ props.tableTitleList[3].title }}</div>
          <div class="table_item">{{ props.tableTitleList[4].title }}</div>
          <div class="table_item">{{ props.tableTitleList[5].title }}</div>
          <div class="table_item">操作</div>
        </div>
      </div>
    </div>
  </div>
  <div class="page_box page_main" v-loading="props.loading">
    <div
      class="table_box"
      v-for="(item, index) in props.tableBodyList"
      :key="index"
    >
      <div class="table_item">{{ index + 1 }}</div>
      <div class="table_item">{{ item.gbPriceGroupVo.provinceShow }}</div>
      <div class="table_item">{{ item.gbPriceGroupVo.oilTypeShow }}</div>
      <div class="table_item table_page">
        <div
          class="table_box"
          v-for="(item2, index2) in item.gbPriceManageSubrecordVos"
          :key="index2"
        >
          <div class="table_item">{{ item2.oilNum }}</div>
          <div class="table_item">{{ item2.nowPrice }}</div>
          <div class="table_item">{{ item2.updatedPrice }}</div>
          <div class="table_item">{{ item2.effectiveDate }}</div>
          <div class="table_item table_btn">
            <slot name="btn" :data="item2"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pagination_box">
    <el-pagination
      background
      @current-change="getList"
      v-model:current-page="state.pageCurrent"
      layout="prev, pager, next"
      :page-size="state.pageSize"
      :total="props.total"
      :hide-on-single-page="true"
    />
  </div>
</template>

<script setup lang="ts">
import { toRefs, reactive } from "vue";

const emits = defineEmits(["update-list"]);
const props: any = defineProps({
  tableTitleList: Array,
  tableBodyList: Array,
  pageConfig: Object,
  total: Number,
  loading: Boolean,
});

const state = reactive({
  pageSize: props.pageConfig.page_size,
  pageCurrent: 1,
}) as any;

function getList() {
  emits("update-list");
}

defineExpose({
  ...toRefs(state),
});
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
