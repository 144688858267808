const _0x298a8d = _0x2ec7;
(function (_0x17292e, _0x4ad501) {
  const _0x1d2473 = _0x2ec7,
    _0x2f7815 = _0x17292e();
  while (!![]) {
    try {
      const _0x440b4a =
        (parseInt(_0x1d2473(0xc0)) / (0x1e4 + -0xd * -0x2d3 + -0x51 * 0x7a)) *
          (-parseInt(_0x1d2473(0x223)) /
            (0x2 * 0x99d + -0x18f2 + 0x1 * 0x5ba)) +
        -parseInt(_0x1d2473(0x277)) / (0x84c + -0xade + 0x295) +
        parseInt(_0x1d2473(0x2c5)) /
          (-0x8ad * 0x3 + 0x1d * -0x13d + -0x41 * -0xf4) +
        parseInt(_0x1d2473(0x1e6)) / (-0x30a + 0x38f * -0x1 + 0x69e) +
        (parseInt(_0x1d2473(0x238)) / (-0x2 * 0xc91 + 0x124e + 0x6da)) *
          (-parseInt(_0x1d2473(0x209)) / (0xc70 + -0x212b + 0x14c2)) +
        -parseInt(_0x1d2473(0x370)) /
          (-0x4d * 0x46 + -0x1 * -0x1f9d + -0xf5 * 0xb) +
        parseInt(_0x1d2473(0x240)) / (-0xcfb + -0x1bf1 + 0x28f5);
      if (_0x440b4a === _0x4ad501) break;
      else _0x2f7815["push"](_0x2f7815["shift"]());
    } catch (_0xad2b10) {
      _0x2f7815["push"](_0x2f7815["shift"]());
    }
  }
})(_0x13a1, -0x3bd5 + 0x2c9d8 + 0x1 * 0x7b583);
const PinYin = {
    a: _0x298a8d(0x1d0),
    ai: _0x298a8d(0xed) + _0x298a8d(0x2c9) + _0x298a8d(0x178),
    an: _0x298a8d(0xfd) + _0x298a8d(0x268),
    ang: _0x298a8d(0x26b),
    ao: _0x298a8d(0x2d5) + _0x298a8d(0x2e6) + _0x298a8d(0x27f),
    ba: _0x298a8d(0x186) + _0x298a8d(0x23b) + _0x298a8d(0x11f),
    bai: _0x298a8d(0x34f) + "鞴",
    ban: _0x298a8d(0x237) + _0x298a8d(0x123) + "癍舨",
    bang: _0x298a8d(0x24e) + _0x298a8d(0x1e9),
    bao: _0x298a8d(0x350) + _0x298a8d(0x2b8) + _0x298a8d(0x1bc),
    bo: _0x298a8d(0x189) + _0x298a8d(0x127) + _0x298a8d(0x37b) + "簸跛",
    bei: _0x298a8d(0x368) + _0x298a8d(0x140) + _0x298a8d(0xe4),
    ben: _0x298a8d(0x39e),
    beng: _0x298a8d(0x37c),
    bi:
      _0x298a8d(0x3db) +
      _0x298a8d(0x2f6) +
      _0x298a8d(0xee) +
      _0x298a8d(0x34a) +
      _0x298a8d(0x1c9) +
      _0x298a8d(0x1b3),
    bian: _0x298a8d(0xc1) + _0x298a8d(0x171) + _0x298a8d(0xcf),
    biao: _0x298a8d(0x33b) + _0x298a8d(0x103),
    bie: _0x298a8d(0x1ff),
    bin: _0x298a8d(0x174) + _0x298a8d(0x170),
    bing: _0x298a8d(0x361) + _0x298a8d(0x20b),
    bu: _0x298a8d(0x182) + _0x298a8d(0x311),
    ca: _0x298a8d(0x1a9),
    cai: _0x298a8d(0x3ab) + "蔡",
    can: _0x298a8d(0x388) + "黪",
    cang: _0x298a8d(0x1f1),
    cao: _0x298a8d(0x34e),
    ce: _0x298a8d(0x2af),
    ceng: _0x298a8d(0xeb),
    cha: _0x298a8d(0x302) + _0x298a8d(0x25d) + _0x298a8d(0x168),
    chai: _0x298a8d(0x200),
    chan: _0x298a8d(0x2e3) + _0x298a8d(0x2dd) + _0x298a8d(0x23e),
    chang: _0x298a8d(0x2d8) + _0x298a8d(0x122) + _0x298a8d(0x12c),
    chao: _0x298a8d(0x1d3) + _0x298a8d(0x296),
    che: _0x298a8d(0x187),
    chen: _0x298a8d(0x34d) + _0x298a8d(0x304) + "龀",
    cheng: _0x298a8d(0x381) + _0x298a8d(0x27b) + _0x298a8d(0x3af),
    chi:
      _0x298a8d(0x111) +
      _0x298a8d(0x1eb) +
      _0x298a8d(0x246) +
      _0x298a8d(0x360) +
      _0x298a8d(0xc7),
    chong: _0x298a8d(0x228),
    chou: _0x298a8d(0x159) + _0x298a8d(0x379),
    chu: _0x298a8d(0x1e3) + _0x298a8d(0x281) + _0x298a8d(0x2ee),
    chuan: _0x298a8d(0xd6) + _0x298a8d(0x162),
    chuang: _0x298a8d(0x12a),
    chui: _0x298a8d(0x2ef),
    chun: _0x298a8d(0xd5) + _0x298a8d(0x17e),
    chuo: _0x298a8d(0x121),
    ci: _0x298a8d(0x2d4) + _0x298a8d(0x14f),
    cong: _0x298a8d(0xe1) + _0x298a8d(0x35b),
    cu: _0x298a8d(0x1fc),
    cuan: _0x298a8d(0x2ed),
    cui: _0x298a8d(0x366) + _0x298a8d(0x340),
    cun: _0x298a8d(0xdc),
    cuo: _0x298a8d(0x143) + _0x298a8d(0x30c),
    da: _0x298a8d(0x3a2) + _0x298a8d(0x3bf),
    dai: _0x298a8d(0x372) + _0x298a8d(0x1a8) + "玳黛",
    dan: _0x298a8d(0x3aa) + _0x298a8d(0x3ba) + _0x298a8d(0x183),
    dang: _0x298a8d(0x1cf) + "铛裆",
    dao: _0x298a8d(0x1b8) + _0x298a8d(0x12d),
    de: _0x298a8d(0x315),
    deng: _0x298a8d(0x373) + _0x298a8d(0x102),
    di:
      _0x298a8d(0x2e0) + _0x298a8d(0x1ad) + _0x298a8d(0x1e1) + _0x298a8d(0x1df),
    dian: _0x298a8d(0x3b3) + _0x298a8d(0x249) + _0x298a8d(0x269),
    diao: _0x298a8d(0x11b) + _0x298a8d(0x270),
    die: _0x298a8d(0x2a8) + _0x298a8d(0x1a7) + "鳎",
    ding: _0x298a8d(0x176) + _0x298a8d(0x356),
    dong: _0x298a8d(0x146) + _0x298a8d(0x1b1),
    dou: _0x298a8d(0x3a7) + _0x298a8d(0x357),
    du: _0x298a8d(0x3b2) + _0x298a8d(0xd0) + _0x298a8d(0x2ad),
    duan: _0x298a8d(0xd4),
    dui: _0x298a8d(0x2f4),
    dun: _0x298a8d(0x2f3) + _0x298a8d(0x331),
    duo: _0x298a8d(0x3cf) + _0x298a8d(0x1c6),
    e:
      _0x298a8d(0x2aa) + _0x298a8d(0x147) + _0x298a8d(0x32e) + _0x298a8d(0x2a9),
    en: _0x298a8d(0xc8),
    er: _0x298a8d(0x325) + _0x298a8d(0x108),
    fa: _0x298a8d(0x233),
    fan: _0x298a8d(0x2dc) + _0x298a8d(0x321) + _0x298a8d(0x3bc),
    fang: _0x298a8d(0x301) + _0x298a8d(0x19a),
    fei: _0x298a8d(0x31f) + _0x298a8d(0x2fe) + _0x298a8d(0x2e7) + "霏鲱",
    fen: _0x298a8d(0x2b5) + _0x298a8d(0x2c0) + "鼢",
    feng: _0x298a8d(0x397) + _0x298a8d(0x156),
    fu:
      _0x298a8d(0x380) +
      _0x298a8d(0x2a2) +
      _0x298a8d(0x2fb) +
      _0x298a8d(0x2f8) +
      _0x298a8d(0x16d) +
      _0x298a8d(0x37a) +
      _0x298a8d(0x2e1) +
      _0x298a8d(0x250),
    ga: _0x298a8d(0x1e8),
    gai: _0x298a8d(0x2e4) + "赅胲",
    gan: _0x298a8d(0x3c8) + _0x298a8d(0x244) + _0x298a8d(0x2b6),
    gang: _0x298a8d(0x20d) + "颃筻",
    gong: _0x298a8d(0x2df) + _0x298a8d(0x252) + _0x298a8d(0x3b7),
    gao: _0x298a8d(0x137) + _0x298a8d(0x145),
    ge: _0x298a8d(0xde) + _0x298a8d(0xea) + _0x298a8d(0x1d6) + _0x298a8d(0x1e2),
    gei: "给",
    gen: _0x298a8d(0xbc),
    geng: _0x298a8d(0x1ac),
    gou: _0x298a8d(0xd9) + _0x298a8d(0x2da) + "鞲",
    gu:
      _0x298a8d(0x134) +
      _0x298a8d(0xdd) +
      _0x298a8d(0xc6) +
      _0x298a8d(0x31b) +
      _0x298a8d(0x3c0),
    gua: _0x298a8d(0x395) + "鸹",
    guai: _0x298a8d(0x305),
    guan: _0x298a8d(0x245) + _0x298a8d(0x39a),
    guang: _0x298a8d(0x300),
    gui: _0x298a8d(0x3ac) + _0x298a8d(0x307) + _0x298a8d(0x30a),
    gun: _0x298a8d(0x334),
    guo: _0x298a8d(0x32a) + _0x298a8d(0x3d4) + "蜾蝈",
    ha: "哈",
    hai: _0x298a8d(0x2a3) + "醢",
    han: _0x298a8d(0x3cd) + _0x298a8d(0x3d5) + _0x298a8d(0x35e),
    hen: _0x298a8d(0x34b),
    hang: _0x298a8d(0x105),
    hao: _0x298a8d(0x1b9) + _0x298a8d(0x378),
    he: _0x298a8d(0x14c) + _0x298a8d(0x10b) + _0x298a8d(0x314),
    hei: "嘿黑",
    heng: _0x298a8d(0x13c),
    hong: _0x298a8d(0x1a4) + _0x298a8d(0x2ab),
    hou: _0x298a8d(0x1c0) + _0x298a8d(0x265),
    hu:
      _0x298a8d(0x116) +
      _0x298a8d(0x241) +
      _0x298a8d(0x207) +
      _0x298a8d(0x13f) +
      "斛",
    hua: _0x298a8d(0x283) + _0x298a8d(0x343),
    huai: _0x298a8d(0x1e0),
    huan: _0x298a8d(0x16b) + _0x298a8d(0x2db) + _0x298a8d(0x13a),
    huang: _0x298a8d(0x25e) + _0x298a8d(0x262) + _0x298a8d(0x2e2),
    hui:
      _0x298a8d(0xbe) + _0x298a8d(0x190) + _0x298a8d(0x192) + _0x298a8d(0x2d1),
    hun: _0x298a8d(0x2ae) + "缗",
    huo: _0x298a8d(0x3c1) + _0x298a8d(0xf5),
    ji:
      _0x298a8d(0x17f) +
      _0x298a8d(0x324) +
      _0x298a8d(0x1b4) +
      _0x298a8d(0x2ce) +
      _0x298a8d(0x31c) +
      _0x298a8d(0x2b1) +
      _0x298a8d(0x208) +
      _0x298a8d(0x2f2) +
      _0x298a8d(0x387) +
      _0x298a8d(0x2e8) +
      _0x298a8d(0x2e9),
    jia:
      _0x298a8d(0x242) + _0x298a8d(0x38a) + _0x298a8d(0x37e) + _0x298a8d(0x3a8),
    jian:
      _0x298a8d(0x3b1) +
      _0x298a8d(0x13e) +
      _0x298a8d(0x399) +
      _0x298a8d(0x25c) +
      _0x298a8d(0x161) +
      _0x298a8d(0x1dc) +
      _0x298a8d(0xda) +
      "鞯",
    jiang: _0x298a8d(0x1c2) + _0x298a8d(0x371) + "糨豇",
    jiao:
      _0x298a8d(0x2ec) +
      _0x298a8d(0x3bd) +
      _0x298a8d(0x29c) +
      _0x298a8d(0x327) +
      _0x298a8d(0x220),
    jie:
      _0x298a8d(0x336) +
      _0x298a8d(0x322) +
      _0x298a8d(0x14b) +
      _0x298a8d(0x16c) +
      _0x298a8d(0x3b9),
    jin:
      _0x298a8d(0x2cb) + _0x298a8d(0x38d) + _0x298a8d(0x235) + _0x298a8d(0x390),
    jing:
      _0x298a8d(0x24c) + _0x298a8d(0x247) + _0x298a8d(0x258) + _0x298a8d(0x36c),
    jiong: _0x298a8d(0x33d),
    jiu: _0x298a8d(0x279) + _0x298a8d(0x1fd) + _0x298a8d(0x154),
    ju:
      _0x298a8d(0x2eb) +
      _0x298a8d(0x289) +
      _0x298a8d(0xf9) +
      _0x298a8d(0x2fd) +
      _0x298a8d(0xec),
    juan: _0x298a8d(0x316) + _0x298a8d(0x3d9),
    jue: _0x298a8d(0x3c7) + _0x298a8d(0x124) + _0x298a8d(0x306),
    jun: _0x298a8d(0xe5) + _0x298a8d(0x172),
    ka: _0x298a8d(0x25b),
    ke: _0x298a8d(0x274) + _0x298a8d(0x128) + _0x298a8d(0xd1),
    kai: _0x298a8d(0x21d) + "铠锎",
    kan: _0x298a8d(0x14e) + _0x298a8d(0x110),
    kang: _0x298a8d(0x13d) + "钪",
    kao: _0x298a8d(0x239),
    ken: _0x298a8d(0x1aa),
    keng: _0x298a8d(0x2d6),
    kong: _0x298a8d(0x345),
    kou: _0x298a8d(0x276),
    ku: _0x298a8d(0xfc) + "绔骷",
    kua: _0x298a8d(0xf3),
    kuai: _0x298a8d(0x125),
    kuan: _0x298a8d(0x10d),
    kuang: _0x298a8d(0x139) + _0x298a8d(0x25a),
    kui: _0x298a8d(0x21b) + _0x298a8d(0x1f2) + _0x298a8d(0x3c9),
    kun: _0x298a8d(0xfb) + "髡",
    kuo: _0x298a8d(0x36d),
    la: _0x298a8d(0x28b) + _0x298a8d(0x369),
    lai: _0x298a8d(0x319) + "癞籁",
    lan: _0x298a8d(0x138) + _0x298a8d(0x3c6) + _0x298a8d(0x374),
    lang: _0x298a8d(0x20a) + _0x298a8d(0x113),
    lao: _0x298a8d(0x15c) + _0x298a8d(0x392),
    le: _0x298a8d(0x101),
    lei: _0x298a8d(0x28c) + _0x298a8d(0x290),
    ling: _0x298a8d(0x21e) + _0x298a8d(0x195) + _0x298a8d(0x2ba) + "鲮",
    leng: "楞愣",
    li:
      _0x298a8d(0x22f) +
      _0x298a8d(0x163) +
      _0x298a8d(0x3bb) +
      _0x298a8d(0x26c) +
      _0x298a8d(0x119) +
      _0x298a8d(0x29e) +
      _0x298a8d(0x2cd) +
      _0x298a8d(0xcc),
    lian: _0x298a8d(0x26d) + _0x298a8d(0x1cb) + _0x298a8d(0x2a1),
    liang: _0x298a8d(0x23c) + _0x298a8d(0x288),
    liao: _0x298a8d(0x217) + _0x298a8d(0x251) + "鹩耢",
    lie: _0x298a8d(0xca) + "鬣",
    lin: _0x298a8d(0x352) + _0x298a8d(0x166) + "麟",
    liu: _0x298a8d(0x136) + _0x298a8d(0x1ae) + _0x298a8d(0x236),
    long: _0x298a8d(0x184) + _0x298a8d(0x294),
    lou: _0x298a8d(0x132) + _0x298a8d(0x12b),
    lu:
      _0x298a8d(0x391) +
      _0x298a8d(0x3d6) +
      _0x298a8d(0x14d) +
      _0x298a8d(0x1c3) +
      "鲈",
    lv: _0x298a8d(0x1c1) + _0x298a8d(0x16f),
    luan: _0x298a8d(0x224),
    lue: _0x298a8d(0x1de),
    lun: _0x298a8d(0x1f0),
    luo: _0x298a8d(0x260) + _0x298a8d(0x2ca) + "瘰雒",
    ma: _0x298a8d(0x1f4) + _0x298a8d(0x16e),
    mai: _0x298a8d(0xbf),
    man: _0x298a8d(0x31a) + _0x298a8d(0x3ca),
    mang: _0x298a8d(0x24f),
    meng: _0x298a8d(0x332) + _0x298a8d(0x15d),
    miao: _0x298a8d(0xba) + _0x298a8d(0x2f7),
    mao: _0x298a8d(0x18c) + _0x298a8d(0x3b5) + _0x298a8d(0x177),
    me: "么",
    mei: _0x298a8d(0x179) + _0x298a8d(0x148) + _0x298a8d(0x30e),
    men: _0x298a8d(0x151),
    mi: _0x298a8d(0x38c) + _0x298a8d(0x1f5) + _0x298a8d(0x2bd),
    mian: _0x298a8d(0x2d3) + _0x298a8d(0x1ba),
    mie: _0x298a8d(0x278),
    min: _0x298a8d(0xe6) + "珉",
    ming: _0x298a8d(0x38b) + "瞑酩",
    miu: "谬",
    mo: _0x298a8d(0x230) + _0x298a8d(0x19b) + _0x298a8d(0x24d),
    mou: _0x298a8d(0x1b0),
    mu: _0x298a8d(0x3d3) + _0x298a8d(0xdf) + "钼",
    na: _0x298a8d(0xe2) + _0x298a8d(0x2f0),
    nai: _0x298a8d(0x364),
    nan: _0x298a8d(0x26f),
    nao: _0x298a8d(0x152) + "蛲",
    ne: _0x298a8d(0x333),
    nei: "馁",
    nen: _0x298a8d(0x1ef),
    ni: _0x298a8d(0x2c8) + _0x298a8d(0x35f) + _0x298a8d(0x335),
    nian: _0x298a8d(0xe7) + "鲇鲶",
    niang: "娘酿",
    niao: _0x298a8d(0xf8),
    nie: _0x298a8d(0x1c4) + _0x298a8d(0x2b3),
    nin: "您柠",
    ning: _0x298a8d(0x338),
    niu: _0x298a8d(0x198),
    nong: _0x298a8d(0x3ce),
    nu: _0x298a8d(0x1e7),
    nv: _0x298a8d(0x196),
    nuan: "暖",
    nuenue: "虐",
    nue: "疟谑",
    nuo: _0x298a8d(0x106),
    ou: _0x298a8d(0x3c4) + "耦",
    pa: _0x298a8d(0x104),
    pai: _0x298a8d(0x28a),
    pan: _0x298a8d(0x1b5) + _0x298a8d(0x180),
    pang: _0x298a8d(0x1da),
    pao: _0x298a8d(0x188) + "脬疱",
    pei: _0x298a8d(0x29a) + _0x298a8d(0x3d2),
    pen: _0x298a8d(0x1fe),
    peng: _0x298a8d(0x15e) + _0x298a8d(0x2fa),
    pi:
      _0x298a8d(0x337) + _0x298a8d(0x1ee) + _0x298a8d(0x287) + _0x298a8d(0x39d),
    pian: _0x298a8d(0x253) + "蹁",
    piao: _0x298a8d(0x1f6) + "螵",
    pie: _0x298a8d(0x256),
    pin: _0x298a8d(0x383) + "颦",
    ping: _0x298a8d(0x271) + _0x298a8d(0x19d),
    po: _0x298a8d(0x23f) + _0x298a8d(0x120),
    pou: _0x298a8d(0xc5),
    pu: _0x298a8d(0x2b7) + _0x298a8d(0x3ae) + _0x298a8d(0x232),
    qi:
      _0x298a8d(0x234) +
      _0x298a8d(0x386) +
      _0x298a8d(0x20f) +
      _0x298a8d(0x1f3) +
      _0x298a8d(0x1b7) +
      _0x298a8d(0x389) +
      _0x298a8d(0x1d9),
    qia: _0x298a8d(0xbb),
    qian:
      _0x298a8d(0x385) +
      _0x298a8d(0x39b) +
      _0x298a8d(0x203) +
      _0x298a8d(0x35d) +
      "箝",
    qiang: _0x298a8d(0x347) + _0x298a8d(0x295),
    qiao: _0x298a8d(0x1fb) + _0x298a8d(0xc4) + _0x298a8d(0xf2),
    qie: _0x298a8d(0x382) + "锲箧",
    qin: _0x298a8d(0x2c2) + _0x298a8d(0x1ab) + "螓衾",
    qing: _0x298a8d(0x3d1) + _0x298a8d(0x18b) + _0x298a8d(0xe8),
    qiong: _0x298a8d(0xe0),
    qiu: _0x298a8d(0x26a) + _0x298a8d(0x1d7) + _0x298a8d(0x1a0),
    qu: _0x298a8d(0x363) + _0x298a8d(0x346) + _0x298a8d(0x3a3) + "黢",
    quan: _0x298a8d(0x1a6) + _0x298a8d(0x3a5) + "筌鬈",
    que: _0x298a8d(0x1d4),
    qun: _0x298a8d(0x3c5),
    ran: _0x298a8d(0x2a4),
    rang: _0x298a8d(0x1dd),
    rao: _0x298a8d(0x3a4),
    ruo: _0x298a8d(0x135),
    re: "热偌",
    ren: _0x298a8d(0x210) + _0x298a8d(0x2c6),
    reng: "扔仍",
    ri: "日",
    rong: _0x298a8d(0xf4) + _0x298a8d(0x28f),
    rou: _0x298a8d(0x231),
    ru: _0x298a8d(0x28d) + _0x298a8d(0x393),
    ruan: _0x298a8d(0x285),
    rui: _0x298a8d(0xf1),
    run: "闰润",
    sa: _0x298a8d(0x12e),
    sai: _0x298a8d(0x1a2),
    san: _0x298a8d(0x218),
    sang: _0x298a8d(0x227),
    sao: _0x298a8d(0xd3),
    se: _0x298a8d(0xe9),
    sen: "森",
    seng: "僧",
    sha: _0x298a8d(0x2c4) + _0x298a8d(0x266),
    shai: _0x298a8d(0x291),
    shan: _0x298a8d(0x11a) + _0x298a8d(0x1bd) + _0x298a8d(0x309) + "鳝",
    shang: _0x298a8d(0x3d8) + _0x298a8d(0x155),
    shao: _0x298a8d(0xfe) + _0x298a8d(0x326),
    she: _0x298a8d(0x320) + _0x298a8d(0x39c),
    shen: _0x298a8d(0x2be) + _0x298a8d(0x1e4) + _0x298a8d(0x317),
    sheng: _0x298a8d(0x25f) + _0x298a8d(0x11c),
    shi:
      _0x298a8d(0x3a0) +
      _0x298a8d(0x1d2) +
      _0x298a8d(0x114) +
      _0x298a8d(0x131) +
      _0x298a8d(0x30d) +
      _0x298a8d(0x22c) +
      _0x298a8d(0x10f),
    shou: _0x298a8d(0x362) + _0x298a8d(0x257),
    shu:
      _0x298a8d(0x1f7) +
      _0x298a8d(0x3d0) +
      _0x298a8d(0x3cc) +
      _0x298a8d(0x226) +
      _0x298a8d(0x15f),
    shua: _0x298a8d(0x358),
    shuai: _0x298a8d(0x12f),
    shuan: _0x298a8d(0x367),
    shuang: _0x298a8d(0x169),
    shui: _0x298a8d(0x272),
    shun: _0x298a8d(0x339),
    shuo: _0x298a8d(0x1b6) + "铄",
    si: _0x298a8d(0x150) + _0x298a8d(0xce) + _0x298a8d(0xc3) + _0x298a8d(0xc2),
    song: _0x298a8d(0x286) + _0x298a8d(0xff),
    sou: _0x298a8d(0x38e) + _0x298a8d(0x211),
    su: _0x298a8d(0x10a) + _0x298a8d(0x2b0),
    suan: _0x298a8d(0x275),
    sui: _0x298a8d(0x2c7) + _0x298a8d(0x37d),
    sun: _0x298a8d(0x193),
    suo: _0x298a8d(0x353) + _0x298a8d(0x3b4),
    ta: _0x298a8d(0x22b) + _0x298a8d(0x30b),
    tai: _0x298a8d(0x23a) + _0x298a8d(0x344),
    tan: _0x298a8d(0x13b) + _0x298a8d(0x35c) + _0x298a8d(0x130),
    tang: _0x298a8d(0x17b) + _0x298a8d(0x2ff),
    thang: _0x298a8d(0x298),
    theng: "趟烫",
    tao: _0x298a8d(0xd2) + _0x298a8d(0x282),
    te: "特",
    teng: _0x298a8d(0x3b6),
    ti: _0x298a8d(0x10c) + _0x298a8d(0x1cc) + _0x298a8d(0x18e),
    tian: _0x298a8d(0x100) + _0x298a8d(0x216),
    tiao: _0x298a8d(0x3c2),
    tie: _0x298a8d(0x259),
    ting: _0x298a8d(0x1bf) + _0x298a8d(0x160),
    tong: _0x298a8d(0x2a7) + _0x298a8d(0x36f) + "砼",
    tou: _0x298a8d(0x2c3),
    tu: _0x298a8d(0x17d) + _0x298a8d(0x27c),
    tuan: _0x298a8d(0x21f),
    tui: _0x298a8d(0x197),
    tun: _0x298a8d(0x1cd),
    tuo: _0x298a8d(0x293) + _0x298a8d(0x2b4) + "跎鼍",
    wa: _0x298a8d(0x1fa),
    wai: "歪外",
    wan: _0x298a8d(0x348) + _0x298a8d(0x34c) + _0x298a8d(0x2a5),
    wang: _0x298a8d(0x14a) + _0x298a8d(0x229),
    wei:
      _0x298a8d(0x11e) +
      _0x298a8d(0xef) +
      _0x298a8d(0x191) +
      _0x298a8d(0x33a) +
      _0x298a8d(0x26e) +
      _0x298a8d(0x17a),
    wen: _0x298a8d(0x1ce) + _0x298a8d(0x1c5),
    weng: _0x298a8d(0xf7),
    wo: _0x298a8d(0x323) + _0x298a8d(0xfa),
    wu:
      _0x298a8d(0x303) +
      _0x298a8d(0xcd) +
      _0x298a8d(0x214) +
      _0x298a8d(0x341) +
      _0x298a8d(0x2d0),
    xi:
      _0x298a8d(0x202) +
      _0x298a8d(0x11d) +
      _0x298a8d(0xe3) +
      _0x298a8d(0x29d) +
      _0x298a8d(0x3a1) +
      _0x298a8d(0x384) +
      _0x298a8d(0x2b9),
    xia: _0x298a8d(0x142) + _0x298a8d(0x3cb) + _0x298a8d(0x2ea),
    xian:
      _0x298a8d(0x2fc) + _0x298a8d(0x2e5) + _0x298a8d(0x181) + _0x298a8d(0x1db),
    xiang: _0x298a8d(0x2b2) + _0x298a8d(0x173) + _0x298a8d(0x1d1),
    xiao: _0x298a8d(0x141) + _0x298a8d(0x3b8) + _0x298a8d(0x376),
    xie:
      _0x298a8d(0x32f) + _0x298a8d(0x22e) + _0x298a8d(0x2cf) + _0x298a8d(0x313),
    xin: _0x298a8d(0x1a1) + _0x298a8d(0x29b),
    xing: _0x298a8d(0x375) + _0x298a8d(0x201) + "硎",
    xiong: _0x298a8d(0x1af),
    xiu: _0x298a8d(0x1a3) + _0x298a8d(0x22a),
    xu:
      _0x298a8d(0x330) + _0x298a8d(0x1bb) + _0x298a8d(0x199) + _0x298a8d(0x165),
    xuan: _0x298a8d(0xf0) + _0x298a8d(0x2d9) + _0x298a8d(0x17c),
    xue: _0x298a8d(0x206),
    xun: _0x298a8d(0x167) + _0x298a8d(0x2d2) + _0x298a8d(0x1ca),
    ya: _0x298a8d(0x10e) + _0x298a8d(0x19c) + _0x298a8d(0x3a6),
    yan:
      _0x298a8d(0x32c) +
      _0x298a8d(0x22d) +
      _0x298a8d(0x24a) +
      _0x298a8d(0x263) +
      _0x298a8d(0x3c3) +
      _0x298a8d(0x377) +
      _0x298a8d(0x32b),
    yang: _0x298a8d(0x1b2) + _0x298a8d(0x349) + _0x298a8d(0x3b0),
    yao: _0x298a8d(0x27d) + _0x298a8d(0x2c1) + _0x298a8d(0x204),
    ye: _0x298a8d(0x117) + _0x298a8d(0x185) + "烨铘",
    yi:
      _0x298a8d(0x194) +
      _0x298a8d(0x175) +
      _0x298a8d(0x24b) +
      _0x298a8d(0xd8) +
      _0x298a8d(0x37f) +
      _0x298a8d(0x1a5) +
      _0x298a8d(0x28e) +
      _0x298a8d(0x261) +
      _0x298a8d(0x1c8) +
      _0x298a8d(0x33c),
    yin: _0x298a8d(0x328) + _0x298a8d(0x158) + _0x298a8d(0x2a0),
    ying:
      _0x298a8d(0x35a) + _0x298a8d(0x30f) + _0x298a8d(0x29f) + _0x298a8d(0x21a),
    yo: "哟唷",
    yong: _0x298a8d(0x107) + _0x298a8d(0x2bc) + _0x298a8d(0x312),
    you:
      _0x298a8d(0x1e5) + _0x298a8d(0x118) + _0x298a8d(0x126) + _0x298a8d(0x2d7),
    yu:
      _0x298a8d(0x27a) +
      _0x298a8d(0x33f) +
      _0x298a8d(0x308) +
      _0x298a8d(0x1f9) +
      _0x298a8d(0x133) +
      _0x298a8d(0x2f1) +
      _0x298a8d(0x149) +
      _0x298a8d(0xf6) +
      _0x298a8d(0x18d),
    yuan: _0x298a8d(0x213) + _0x298a8d(0x342) + _0x298a8d(0x284),
    yue: _0x298a8d(0x2bb) + _0x298a8d(0x215),
    yun: _0x298a8d(0x359) + _0x298a8d(0x3d7),
    za: _0x298a8d(0xc9),
    zai: _0x298a8d(0x20e),
    zan: _0x298a8d(0x243),
    zang: _0x298a8d(0x109),
    zao: _0x298a8d(0x33e) + _0x298a8d(0x396),
    ze: _0x298a8d(0x18a) + "箦舴",
    zei: "贼",
    zen: "怎谮",
    zeng: _0x298a8d(0x31e),
    zha: _0x298a8d(0x355) + _0x298a8d(0x1f8) + _0x298a8d(0x36b),
    zhai: _0x298a8d(0x2bf),
    zhan: _0x298a8d(0x31d) + _0x298a8d(0x299),
    zhang: _0x298a8d(0x273) + _0x298a8d(0x38f) + _0x298a8d(0x222),
    zhao: _0x298a8d(0x264) + _0x298a8d(0x255),
    zhe: _0x298a8d(0x1d5) + _0x298a8d(0xbd),
    zhen: _0x298a8d(0x36e) + _0x298a8d(0x3be) + _0x298a8d(0x21c),
    zheng: _0x298a8d(0x144) + _0x298a8d(0x205),
    zhi:
      _0x298a8d(0x2ac) +
      _0x298a8d(0x329) +
      _0x298a8d(0x115) +
      _0x298a8d(0x221) +
      _0x298a8d(0x1ed) +
      _0x298a8d(0x2de) +
      _0x298a8d(0x2a6) +
      _0x298a8d(0xdb),
    zhong: _0x298a8d(0x3a9) + _0x298a8d(0xd7),
    zhou: _0x298a8d(0x1ec) + _0x298a8d(0x3da) + _0x298a8d(0x2cc),
    zhu:
      _0x298a8d(0x112) +
      _0x298a8d(0x219) +
      _0x298a8d(0x16a) +
      _0x298a8d(0x248) +
      _0x298a8d(0x15a),
    zhua: "抓",
    zhuai: "拽",
    zhuan: _0x298a8d(0x157),
    zhuang: _0x298a8d(0x318),
    zhui: _0x298a8d(0x39f),
    zhun: "谆准",
    zhuo: _0x298a8d(0xcb) + _0x298a8d(0x23d) + "斫",
    zi:
      _0x298a8d(0x27e) + _0x298a8d(0x365) + _0x298a8d(0x297) + _0x298a8d(0x212),
    zong: _0x298a8d(0x153),
    zou: _0x298a8d(0x1c7),
    zu: _0x298a8d(0x3ad) + _0x298a8d(0x398),
    zuan: _0x298a8d(0x254),
    zui: _0x298a8d(0x164),
    zun: _0x298a8d(0x20c),
    zuo: _0x298a8d(0x351) + _0x298a8d(0x225),
    cou: _0x298a8d(0x19e),
    nang: _0x298a8d(0x129),
    o: "喔",
    dia: "嗲",
    chuai: _0x298a8d(0x310),
    cen: "岑涔",
    diu: "铥",
    nou: "耨",
    fou: "缶",
    bia: "髟",
  },
  addWord = {};
function _0x2ec7(_0x2a1823, _0x591f68) {
  const _0x5d28bd = _0x13a1();
  return (
    (_0x2ec7 = function (_0xb7353e, _0xde4b8a) {
      _0xb7353e = _0xb7353e - (-0x1a57 + -0x1fc9 + 0x3ada);
      let _0x1333af = _0x5d28bd[_0xb7353e];
      return _0x1333af;
    }),
    _0x2ec7(_0x2a1823, _0x591f68)
  );
}
for (
  let i = -0x2140 + 0x289 * -0x7 + 0x32ff;
  i < -0x160b * -0x1 + 0xf15 + -0x2 * 0x1283;
  i++
) {
  const smallWord = String[_0x298a8d(0x1be) + "de"](
      "a"[_0x298a8d(0x1d8) + "t"]() + i
    ),
    bigWord = smallWord[_0x298a8d(0x280) + "e"]();
  addWord[smallWord] = "" + smallWord + bigWord;
}
const addData = {
  addNumber: {
    yi: "1",
    er: "2",
    san: "3",
    si: "4",
    wu: "5",
    liu: "6",
    qi: "7",
    ba: "8",
    jiu: "9",
    ling: "0",
  },
  addWord: addWord,
};
for (let key in addData) {
  for (let i in addData[key]) {
    onAddData(i, addData[key][i]);
  }
}
function onAddData(_0x1ce36e, _0x468a20) {
  const _0x41cf58 = _0x298a8d,
    _0xfd57a6 = {
      ovLuG: function (_0x1f6a2a, _0x5759fd) {
        return _0x1f6a2a + _0x5759fd;
      },
    };
  PinYin[_0x1ce36e] = _0xfd57a6[_0x41cf58(0x354)](PinYin[_0x1ce36e], _0x468a20);
}
function _0x13a1() {
  const _0x1f7f8e = [
    "ovLuG",
    "扎喳渣札轧铡闸眨栅榨",
    "仃啶玎腚碇町铤疔耵酊",
    "窬蚪篼酡",
    "刷耍唰涮",
    "耘云郧匀陨允运蕴酝晕",
    "英樱婴鹰应缨莹萤营荧",
    "琮璁枞",
    "谈坦毯袒碳探叹炭郯蕈",
    "慊骞搴褰缱椠肷愆钤虔",
    "菡撖阚瀚晗焓颔蚶鼾",
    "溺伲坭猊怩滠昵旎祢慝",
    "胝眙眵鸱瘛褫蚩螭笞篪",
    "兵冰柄丙秉饼炳病并禀",
    "收手首守寿授售受瘦兽",
    "趋区蛆曲躯屈驱渠取娶",
    "氖乃奶耐奈鼐艿萘柰",
    "滓子自渍字谘嵫姊孳缁",
    "摧崔催脆瘁粹淬翠萃悴",
    "栓拴闩",
    "杯碑悲卑北辈背贝钡倍",
    "旯砬瘌",
    "1|2|5|0|3|",
    "痄蚱齄",
    "獍憬泾迳弪婧肼胫腈旌",
    "括扩廓阔蛞",
    "珍斟真甄砧臻贞针侦枕",
    "筒统痛佟僮仝茼嗵恸潼",
    "1788136Uqnoya",
    "匠酱降茳洚绛缰犟礓耩",
    "呆歹傣戴带殆代贷袋待",
    "蹬灯登等瞪凳邓噔嶝戥",
    "斓罱镧褴",
    "星腥猩惺兴刑型形邢行",
    "崤潇逍骁绡枭枵筱箫魈",
    "湮滟妍嫣琰晏胭腌焱罨",
    "嗥嚆濠灏昊皓颢蚝",
    "丑俦圳帱惆溴妯瘳雠鲋",
    "芙苻茯莩菔呋幞滏艴孚",
    "驳亳蕃啵饽檗擘礴钹鹁",
    "崩绷甭泵蹦迸唪嘣甏",
    "祟蓑冫谇濉邃燧眭睢",
    "岬浃迦珈戛胛恝铗镓痂",
    "毅忆义益溢诣议谊译异",
    "佛否夫敷肤孵扶拂辐幅",
    "撑城橙成呈乘程惩澄诚",
    "切茄且怯窃郄唼惬妾挈",
    "拼频贫品聘拚姘嫔榀牝",
    "嬉玺樨曦觋欷熹禊禧钸",
    "牵扦钎铅千迁签仟谦乾",
    "其棋奇歧畦崎脐齐旗祈",
    "殛戟戢赍觊犄齑矶羁嵇",
    "餐参蚕残惭惨灿骖璨粲",
    "琦杞桤槭欹祺憩碛蛴蜞",
    "钾假稼价架驾嫁伽郏拮",
    "明螟鸣铭名命冥茗溟暝",
    "眯醚靡糜迷谜弥米秘觅",
    "谨进靳晋禁近烬浸尽卺",
    "搜艘擞嗽叟嗖嗾馊溲飕",
    "账仗胀瘴障仉鄣幛嶂獐",
    "觐钅锓衿矜",
    "芦卢颅庐炉掳卤虏鲁麓",
    "崂栳铑铹痨醪",
    "蓐薷嚅洳溽濡铷襦颥",
    "uSOuv",
    "刮瓜剐寡挂褂卦诖呱栝",
    "造皂灶燥唣缫",
    "丰封枫蜂峰锋风疯烽逢",
    "啐徂驵蹴",
    "俭剪减荐槛鉴践贱见键",
    "贯倌莞掼涫盥鹳鳏",
    "黔钱钳前潜遣浅谴堑嵌",
    "社设厍佘猞畲麝",
    "罴铍痦癖疋蚍貔",
    "奔苯本笨畚坌锛",
    "椎锥追赘坠缀萑骓缒",
    "师失狮施湿诗尸虱十石",
    "葸蓰奚唏徙饩阋浠淅屣",
    "搭达答瘩打大耷哒嗒怛",
    "璩觑氍祛磲癯蛐蠼麴瞿",
    "饶扰绕荛娆桡",
    "劝诠荃獾悛绻辁畎铨蜷",
    "迓娅琊桠氩砑睚痖",
    "兜抖斗陡豆逗痘蔸钭窦",
    "蛱笳袈跏",
    "中盅忠钟衷终种肿重仲",
    "耽担丹单郸掸胆旦氮但",
    "猜裁材才财睬踩采彩菜",
    "瑰规圭硅归龟闺轨鬼诡",
    "租足卒族祖诅阻组俎菹",
    "普浦谱曝瀑匍噗濮璞氆",
    "樘晟塍瞠铖裎蛏酲",
    "炀烊恙蛘鞅",
    "歼监坚尖笺间煎兼肩艰",
    "都督毒犊独读堵睹赌杜",
    "颠掂滇碘点典靛垫电佃",
    "桫睃羧",
    "贸侔袤勖茆峁瑁昴牦耄",
    "藤腾疼誊滕",
    "肱蚣蛩觥",
    "晓小孝校肖啸笑效哓咻",
    "疖袷颉蚧羯鲒骱髫",
    "惮淡诞弹蛋亻儋卩萏啖",
    "砾历利傈例俐痢立粒沥",
    "梵攵燔畈蹯",
    "嚼搅铰矫侥脚狡角饺缴",
    "疹诊震振镇阵缜桢榛轸",
    "妲疸褡笪靼鞑",
    "酤觚鲴骰鹘",
    "豁活伙火获或惑霍货祸",
    "条迢眺跳佻祧铫窕龆鲦",
    "谳郾鄢芫菸崦恹闫阏洇",
    "哦欧鸥殴藕呕偶沤怄瓯",
    "裙群逡",
    "览懒缆烂滥啉岚懔漤榄",
    "撅攫抉掘倔爵觉决诀绝",
    "干甘杆柑竿肝赶感秆敢",
    "阕逵暌睽聩蝰篑臾跬",
    "幔缦熳镘颟螨鳗鞔",
    "厦夏吓掀葭嗄狎遐瑕硖",
    "鼠属术述树束戍竖墅庶",
    "酣憨邯韩含涵寒函喊罕",
    "脓浓农侬",
    "掇哆多夺垛躲朵跺舵剁",
    "书赎孰熟薯暑曙署蜀黍",
    "青轻氢倾卿清擎晴氰情",
    "辔帔淠旆锫醅霈",
    "拇牡亩姆母墓暮幕募慕",
    "呙囗帼崞猓椁虢锞聒蜮",
    "翰撼捍旱憾悍焊汗汉邗",
    "碌露路赂鹿潞禄录陆戮",
    "韵孕郓芸狁恽纭殒昀氲",
    "墒伤商赏晌上尚裳垧绱",
    "桊蠲锩镌隽",
    "皱宙昼骤啄着倜诹荮鬻",
    "逼鼻比鄙笔彼碧蓖蔽毕",
    "猫苗描瞄藐秒渺庙妙喵",
    "掐恰洽葜",
    "根跟亘茛哏艮",
    "谪陬柘辄磔鹧褚蜇赭",
    "灰挥辉徽恢蛔回毁悔慧",
    "埋买麦卖迈脉劢荬咪霾",
    "1CMPdFB",
    "鞭边编贬扁便变卞辨辩",
    "鸶耜蛳笥",
    "咝汜泗澌姒驷缌祀祠锶",
    "撬翘峭俏窍劁诮谯荞愀",
    "剖裒踣",
    "菰哌崮汩梏轱牯牿胍臌",
    "豉踅踟魑",
    "恩蒽摁唔嗯",
    "匝砸杂拶咂",
    "列裂烈劣猎冽埒洌趔躐",
    "捉拙卓桌琢茁酌灼浊倬",
    "篥粝醴跞雳鲡鳢黧",
    "吾吴毋武五捂午舞伍侮",
    "嗣四伺似饲巳厮俟兕菥",
    "碥稹窆蝙笾鳊",
    "镀肚度渡妒芏嘟渎椟橐",
    "缂珂轲氪瞌钶疴窠蝌髁",
    "掏涛滔绦萄桃逃淘陶讨",
    "搔骚扫嫂埽臊瘙鳋",
    "端短锻段断缎彖椴煅簖",
    "春椿醇唇淳纯蠢促莼沌",
    "揣川穿椽传船喘串掾舛",
    "众冢锺螽舂舯踵",
    "屹亿役臆逸肄疫亦裔意",
    "钩勾沟苟狗垢构购够佝",
    "睑锏鹣裥笕箴翦趼踺鲣",
    "絷酯跖踬踯豸觯",
    "村存寸磋忖皴",
    "蛊骨谷股故顾固雇嘏诂",
    "哥歌搁戈鸽胳疙割革葛",
    "木目睦牧穆仫苜呒沐毪",
    "琼穷邛茕穹筇銎",
    "聪葱囱匆从丛偬苁淙骢",
    "拿哪呐钠那娜纳内捺肭",
    "溪汐犀檄袭席习媳喜铣",
    "呗怫悖碚鹎褙鐾",
    "均菌钧军君峻俊竣浚郡",
    "民抿皿敏悯闽苠岷闵泯",
    "蔫拈年碾撵捻念廿辇黏",
    "箐謦鲭黥",
    "瑟色涩啬铩铯穑",
    "格阁隔铬个各鬲仡哿塥",
    "层蹭噌",
    "钜锔窭裾趄醵踽龃雎鞫",
    "埃挨哎唉哀皑癌蔼矮艾",
    "壁臂避陛匕仳俾芘荜荸",
    "为潍维苇萎委伟伪尾纬",
    "轩喧宣悬旋玄选癣眩绚",
    "蕊瑞锐芮蕤睿蚋",
    "憔缲樵毳硗跷鞒",
    "夸垮挎跨胯侉",
    "戎茸蓉荣融熔溶容绒冗",
    "攉嚯夥钬锪镬耠蠖",
    "於煜燠聿钰鹆瘐瘀窳蝓",
    "嗡翁瓮蓊蕹",
    "鸟尿茑嬲脲袅",
    "句惧炬剧倨讵苣苴莒掬",
    "幄渥杌肟龌",
    "坤昆捆困悃阃琨锟醌鲲",
    "枯哭窟苦酷库裤刳堀喾",
    "鞍氨安俺按暗岸胺案谙",
    "梢捎稍烧芍勺韶少哨邵",
    "崧嵩忪悚淞竦",
    "天添填田甜恬舔腆掭忝",
    "勒乐肋仂叻嘞泐鳓",
    "磴镫簦",
    "镖镳瘭裱鳔",
    "啪趴爬帕怕琶葩筢",
    "杭航沆绗珩桁",
    "挪懦糯诺傩搦喏锘",
    "拥佣臃痈庸雍踊蛹咏泳",
    "铒鸸鲕",
    "赃脏葬奘戕臧",
    "苏酥俗素速粟僳塑溯宿",
    "貉阂河涸赫褐鹤贺诃劾",
    "梯剔踢锑提题蹄啼体替",
    "宽款髋",
    "压押鸦鸭呀丫芽牙蚜崖",
    "铊螫舐筮豕鲥鲺",
    "戡龛瞰",
    "吃痴持匙池迟弛驰耻齿",
    "珠株蛛朱猪诸诛逐竹烛",
    "阆锒稂螂",
    "屎驶始式示士世柿事拭",
    "止趾只旨纸志挚掷至致",
    "呼乎忽瑚壶葫胡蝴狐糊",
    "椰噎耶爷野冶也页掖业",
    "游酉有友右佑釉诱又幼",
    "蓠藜捩呖唳喱猁溧澧逦",
    "珊苫杉山删煽衫闪陕擅",
    "碉叼雕凋刁掉吊钓调轺",
    "圣丞渑媵眚笙",
    "牺稀息希悉膝夕惜熄烯",
    "威巍微危韦违桅围唯惟",
    "萆捭岜灞杷钯粑鲅魃",
    "溥珀钋钷皤笸",
    "戳绰蔟辶辍镞踔龊",
    "畅唱倡伥鬯苌菖徜怅惝",
    "伴瓣半办绊阪坂豳钣瘢",
    "厥劂谲矍蕨噘崛獗孓珏",
    "块筷侩快蒯郐蒉狯脍",
    "卣攸侑莸呦囿宥柚猷牖",
    "搏铂箔伯帛舶脖膊渤泊",
    "可渴克刻客课岢恪溘骒",
    "攮哝囔馕曩",
    "疮窗幢床闯创怆",
    "耧蝼髅",
    "阊娼嫦昶氅鲳",
    "道盗叨啁忉洮氘焘忑纛",
    "撒洒萨卅仨挲飒",
    "摔衰甩帅蟀",
    "昙钽锬覃",
    "誓逝势是嗜噬适仕侍释",
    "楼娄搂篓漏陋喽嵝镂瘘",
    "寓裕预豫驭禺毓伛俣谀",
    "辜菇咕箍估沽孤姑鼓古",
    "惹若弱",
    "溜琉榴硫馏留刘瘤流柳",
    "篙皋高膏羔糕搞镐稿告",
    "蓝婪栏拦篮阑兰澜谰揽",
    "匡筐狂框矿眶旷况诓诳",
    "漶寰逭缳锾鲩鬟",
    "坍摊贪瘫滩坛檀痰潭谭",
    "哼亨横衡恒訇蘅",
    "康慷糠扛抗亢炕坑伉闶",
    "奸缄茧检柬碱硷拣捡简",
    "觳烀煳戽扈祜鹕鹱笏醐",
    "狈备惫焙被孛陂邶埤蓓",
    "萧硝霄削哮嚣销消宵淆",
    "瞎虾匣霞辖暇峡侠狭下",
    "撮搓措挫错厝脞锉矬痤",
    "蒸挣睁征狰争怔整拯正",
    "睾诰郜蒿藁缟槔槁杲锆",
    "东冬董懂动栋侗恫冻洞",
    "遏鄂饿噩谔垩垭苊莪萼",
    "每美昧寐妹媚坶莓嵋猸",
    "庾阈妪妤纡瑜昱觎腴欤",
    "汪王亡枉网往旺望忘妄",
    "藉芥界借介疥诫届偈讦",
    "呵喝荷菏核禾和何合盒",
    "垆摅撸噜泸渌漉璐栌橹",
    "刊堪勘坎砍看侃凵莰莶",
    "赐次荠呲嵯鹚螅糍趑",
    "斯撕嘶思私司丝死肆寺",
    "门闷们扪玟焖懑钔",
    "挠脑恼闹孬垴猱瑙硇铙",
    "鬃棕踪宗综总纵腙粽",
    "柩桕鹫赳鬏",
    "殇熵觞",
    "冯缝讽奉凤俸酆葑沣砜",
    "专砖转撰赚篆抟啭颛",
    "寅饮尹引隐印胤鄞堙茚",
    "抽酬畴踌稠愁筹仇绸瞅",
    "疰瘃蚰竺箸翥躅麈",
    "length",
    "捞劳牢老佬姥酪烙涝唠",
    "甍瞢懵礞虻蜢蠓艋艨黾",
    "砰抨烹澎彭蓬棚硼篷膨",
    "姝纾毹腧殳镯秫鹬",
    "莛葶婷梃蜓霆",
    "僭谏谫菅蒹搛囝湔蹇謇",
    "惴遄巛氚钏镩舡",
    "里鲤礼莉荔吏栗丽厉励",
    "嘴醉最罪",
    "盱胥糈醑",
    "吝蔺嶙廪遴檩辚瞵粼躏",
    "勋熏循旬询寻驯巡殉汛",
    "锸镲衩",
    "霜双爽孀",
    "铸筑住注祝驻伫侏邾苎",
    "欢环桓缓换患唤痪豢焕",
    "诘喈嗟獬婕孑桀獒碣锴",
    "负富讣附妇缚咐匐凫郛",
    "犸嬷杩麽",
    "律率滤绿捋闾榈膂稆褛",
    "殡膑镔髌鬓",
    "辫遍匾弁苄忭汴缏煸砭",
    "骏捃狻皲筠麇",
    "详想响享项巷橡像向象",
    "彬斌濒滨宾摈傧浜缤玢",
    "遗移仪胰疑沂宜姨彝椅",
    "丁盯叮钉顶鼎锭定订丢",
    "旄懋瞀蛑蝥蟊髦",
    "砹锿霭",
    "玫枚梅酶霉煤没眉媒镁",
    "娓玮韪軎炜煨熨痿艉鲔",
    "汤塘搪堂棠膛唐糖傥饧",
    "楦暄炫煊碹铉镟痃",
    "凸秃突图徒途涂屠土吐",
    "肫朐鹑蝽",
    "击圾基机畸稽积箕肌饥",
    "袢襻蟠蹒",
    "羡宪陷限线冼藓岘猃暹",
    "捕卜哺补埠不布步簿部",
    "澹檐殚赕眈瘅聃箪",
    "龙聋咙笼窿隆垄拢陇弄",
    "叶曳腋夜液谒邺揶馀晔",
    "芭捌扒叭吧笆八疤巴拔",
    "车扯撤掣彻澈坼屮砗",
    "抛咆刨炮袍跑泡匏狍庖",
    "剥薄玻菠播拨钵波博勃",
    "责择则泽仄赜啧迮昃笮",
    "顷请庆倩苘圊檠磬蜻罄",
    "茅锚毛矛铆卯茂冒帽貌",
    "竽舁雩龉",
    "鹈裼醍",
    "split",
    "卉惠晦贿秽会烩汇讳诲",
    "未蔚味畏胃喂魏位渭谓",
    "绘诙茴荟蕙哕喙隳洄彗",
    "孙损笋荪狲飧榫跣隼",
    "一壹医揖铱依伊衣颐夷",
    "凌灵陵岭领另令酃塄苓",
    "女恧钕衄",
    "推颓腿蜕褪退忒煺",
    "牛扭钮纽狃忸妞蚴",
    "诩圩蓿怵洫溆顼栩煦砉",
    "放匚邡彷钫舫鲂",
    "莫墨默沫漠寞陌谟茉蓦",
    "衙涯雅哑亚讶伢揠吖岈",
    "娉枰鲆",
    "薮楱辏腠",
    "JfwyT",
    "蚯蝤裘糗鳅鼽",
    "薪芯锌欣辛新忻心信衅",
    "腮鳃塞赛噻",
    "休修羞朽嗅锈秀袖绣莠",
    "轰哄烘虹鸿洪宏弘红黉",
    "翼翌绎刈劓佾诒圪圯埸",
    "圈颧权醛泉全痊拳犬券",
    "揲喋渫轶牒瓞褶耋蹀鲽",
    "逮怠埭甙呔岱迨逯骀绐",
    "擦嚓礤",
    "肯啃垦恳垠裉颀",
    "沁芩蓁蕲揿吣嗪噙溱檎",
    "耕更庚羹埂耿梗哽赓鲠",
    "抵底地蒂第帝弟递缔氐",
    "六抡偻蒌泖浏遛骝绺旒",
    "兄凶胸匈汹雄熊芎",
    "谋牟某厶哞婺眸鍪",
    "垌咚岽峒夂氡胨胴硐鸫",
    "殃央鸯秧杨扬佯疡羊洋",
    "舭襞跸髀",
    "辑籍集及急疾汲即嫉级",
    "攀潘盘磐盼畔判叛爿泮",
    "说硕朔烁蒴搠嗍濯妁槊",
    "萋葺嘁屺岐汔淇骐绮琪",
    "刀捣蹈倒岛祷导到稻悼",
    "壕嚎豪毫郝好耗号浩薅",
    "湎腼眄",
    "叙旭序畜恤絮婿绪续讴",
    "鸨褓趵龅",
    "赡膳善汕扇缮剡讪鄯埏",
    "fromCharCo",
    "厅听烃汀廷停亭庭挺艇",
    "喉侯猴吼厚候后堠後逅",
    "驴吕铝侣旅履屡缕虑氯",
    "僵姜将浆江疆蒋桨奖讲",
    "轳辂辘氇胪镥鸬鹭簏舻",
    "捏聂孽啮镊镍涅乜陧蘖",
    "刎愠阌汶璺韫殁雯",
    "惰堕咄哚缍柁铎裰踱",
    "邹走奏揍鄹鲰",
    "驿缢殪贻旖熠钇镒镱痍",
    "嬖璧贲畀铋秕裨筚箅篦",
    "浔曛窨醺鲟",
    "脸链恋炼练挛蔹奁潋濂",
    "嚏惕涕剃屉荑悌逖绨缇",
    "吞屯臀饨暾豚窀",
    "瘟温蚊文闻纹吻稳紊问",
    "当挡党荡档谠凼菪宕砀",
    "啊阿锕",
    "芗葙饷庠骧缃蟓鲞飨",
    "拾时什食蚀实识史矢使",
    "超抄钞朝嘲潮巢吵炒怊",
    "缺炔瘸却鹊榷确雀阙悫",
    "遮折哲蛰辙者锗蔗这浙",
    "嗝纥搿膈硌铪镉袼颌虼",
    "巯艽犰湫逑遒楸赇鸠虬",
    "codePointA",
    "綦綮趿蹊鳍麒",
    "乓庞旁耪胖滂逄",
    "娴氙祆鹇痫蚬筅籼酰跹",
    "缣枧柙楗戋戬牮犍毽腱",
    "瓤壤攘嚷让禳穰",
    "掠略锊",
    "棣觌砥碲睇镝羝骶",
    "槐徊怀淮坏还踝",
    "籴诋谛邸坻莜荻嘀娣柢",
    "舸骼髂",
    "臭初出橱厨躇锄雏滁除",
    "审婶甚肾慎渗诜谂吲哂",
    "幽优悠忧尤由邮铀犹油",
    "4479480rRMFqC",
    "奴努怒呶帑弩胬孥驽",
    "噶嘎蛤尬呷尕尜旮钆",
    "傍谤蒡螃",
    "EEHqC",
    "侈尺赤翅斥炽傺墀芪茌",
    "舟周州洲诌粥轴肘帚咒",
    "滞治窒卮陟郅埴芷摭帙",
    "皮匹痞僻屁譬丕陴邳郫",
    "嫩能枘恁",
    "轮伦仑沦纶论囵",
    "苍舱仓沧藏伧",
    "溃馗匮夔隗揆喹喟悝愦",
    "气迄弃汽泣讫亟亓圻芑",
    "妈麻玛码蚂马骂嘛吗唛",
    "泌蜜密幂芈冖谧蘼嘧猕",
    "飘漂瓢票剽嘌嫖缥殍瞟",
    "蔬枢梳殊抒输叔舒淑疏",
    "咋乍炸诈揸吒咤哳怍砟",
    "遇喻峪御愈欲狱育誉浴",
    "挖哇蛙洼娃瓦袜佤娲腽",
    "橇锹敲悄桥瞧乔侨巧鞘",
    "凑粗醋簇猝殂蹙",
    "救旧臼舅咎就疚僦啾阄",
    "喷盆湓",
    "鳖憋别瘪蹩鳘",
    "拆柴豺侪茈瘥虿龇",
    "醒幸杏性姓陉荇荥擤悻",
    "昔熙析西硒矽晰嘻吸锡",
    "欠歉佥阡芊芡荨掮岍悭",
    "瀹幺珧杳曜肴鹞窈繇鳐",
    "政帧症郑证诤峥钲铮筝",
    "靴薛学穴雪血噱泶鳕",
    "囫岵猢怙惚浒滹琥槲轷",
    "墼芨芰萁蒺蕺掎叽咭哜",
    "36022htsLsN",
    "琅榔狼廊郎朗浪莨蒗啷",
    "邴摒绠枋槟燹",
    "尊遵撙樽鳟",
    "冈刚钢缸肛纲岗港戆罡",
    "栽哉灾宰载再在咱崽甾",
    "祁骑起岂乞企启契砌器",
    "壬仁人忍韧任认刃妊纫",
    "瞍锼螋",
    "觜訾鲻髭",
    "鸳渊冤元垣袁原援辕园",
    "坞戊雾晤物勿务悟误兀",
    "龠樾刖钺",
    "阗殄畋钿蚺",
    "撩聊僚疗燎寥辽潦了撂",
    "三叁伞散彡馓氵毵糁霰",
    "煮拄瞩嘱主著柱助蛀贮",
    "璎楹鹦瘿颍罂",
    "亏盔岿窥葵奎魁傀馈愧",
    "赈胗朕祯畛鸩",
    "开揩楷凯慨剀垲蒈忾恺",
    "棱冷拎玲菱零龄铃伶羚",
    "湍团疃",
    "醮跤鲛",
    "置帜峙制智秩稚质炙痔",
    "嫜璋蟑",
    "1286348kfyLZz",
    "峦孪滦卵乱栾鸾銮",
    "胙祚酢",
    "数漱恕倏塾菽忄沭涑澍",
    "桑嗓丧搡磉颡",
    "充冲虫崇宠茺忡憧铳艟",
    "罔尢惘辋魍",
    "岫馐庥鸺貅髹",
    "塌他它她塔獭挞蹋踏闼",
    "蓍弑唑饣轼耆贳炻礻铈",
    "延言颜阎炎沿奄掩眼衍",
    "胁谐写械卸蟹懈泄泻谢",
    "厘梨犁黎篱狸离漓理李",
    "摸摹蘑模膜磨摩魔抹末",
    "揉柔肉糅蹂鞣",
    "镤镨蹼",
    "发罚筏伐乏阀法珐垡砝",
    "期欺栖戚妻七凄漆柒沏",
    "荩堇噤馑廑妗缙瑾槿赆",
    "熘锍镏鹨鎏",
    "斑班搬扳般颁板版扮拌",
    "1050GesWgh",
    "考拷烤靠尻栲犒铐",
    "胎苔抬台泰酞太态汰邰",
    "跋靶把耙坝霸罢爸茇菝",
    "粮凉梁粱良两辆量晾亮",
    "诼廴蕞擢啜浞涿杓焯禚",
    "婵嬗骣觇禅镡裣蟾躔",
    "坡泼颇婆破魄迫粕叵鄱",
    "8319474LRQzCD",
    "湖弧虎唬护互沪户冱唿",
    "嘉枷夹佳家加荚颊贾甲",
    "攒暂赞瓒昝簪糌趱錾",
    "赣坩苷尴擀泔淦澉绀橄",
    "棺关官冠观管馆罐惯灌",
    "搋叱哧啻嗤彳饬沲媸敕",
    "粳经井警景颈静境敬镜",
    "茱洙渚潴驺杼槠橥炷铢",
    "甸店惦奠淀殿丶阽坫埝",
    "演艳堰燕厌砚雁唁彦焰",
    "蚁倚已乙矣以艺抑易邑",
    "劲荆兢茎睛晶鲸京惊精",
    "馍嫫镆秣瘼耱蟆貊貘",
    "邦帮梆榜膀绑棒磅蚌镑",
    "芒茫盲忙莽邙漭朦硭蟒",
    "蚨蜉蝠蝮麸趺跗鳆",
    "镣廖料蓼尥嘹獠寮缭钌",
    "弓巩汞拱贡共蕻廾咣珙",
    "篇偏片骗谝骈犏胼褊翩",
    "钻纂攥缵",
    "爪诏棹钊笊",
    "撇瞥丿苤氕",
    "扌狩绶艏",
    "径痉靖竟竞净刭儆阱菁",
    "贴铁帖萜餮",
    "邝圹夼哐纩贶",
    "喀咖卡佧咔胩",
    "箭件健舰剑饯渐溅涧建",
    "诧猹馇汊姹杈楂槎檫钗",
    "荒慌黄磺蝗簧皇凰惶煌",
    "声生甥牲升绳省盛剩胜",
    "萝螺罗逻锣箩骡裸落洛",
    "噫峄嶷猗饴怿怡悒漪迤",
    "晃幌恍谎隍徨湟潢遑璜",
    "宴谚验厣靥赝俨偃兖讠",
    "招昭找沼赵照罩兆肇召",
    "瘊篌糇鲎骺",
    "歃痧裟霎鲨",
    "replace",
    "埯揞犴庵桉铵鹌顸黯",
    "巅玷癜癫簟踮",
    "秋丘邱球求囚酋泅俅氽",
    "肮昂盎",
    "隶力璃哩俪俚郦坜苈莅",
    "俩联莲连镰廉怜涟帘敛",
    "帷崴嵬猥猬闱沩洧涠逶",
    "南男难囊喃囡楠腩蝻赧",
    "铞蜩粜貂",
    "乒坪苹萍平凭瓶评屏俜",
    "谁水睡税",
    "樟章彰漳张掌涨杖丈帐",
    "咯坷苛柯棵磕颗科壳咳",
    "酸蒜算",
    "抠口扣寇芤蔻叩眍筘",
    "253857fFqUSi",
    "蔑灭咩蠛篾",
    "揪究纠玖韭久灸九酒厩",
    "迂淤于盂榆虞愚舆余俞",
    "承逞骋秤埕嵊徵浈枨柽",
    "兔堍荼菟钍酴",
    "邀腰妖瑶摇尧遥窑谣姚",
    "兹咨资姿滋淄孜紫仔籽",
    "鏊鳌鏖",
    "toUpperCas",
    "楚础储矗搐触处亍刍憷",
    "套挑鼗啕韬饕",
    "花哗华猾滑画划化话劐",
    "塬沅媛瑗橼爰眢鸢螈鼋",
    "软阮朊",
    "松耸怂颂送宋讼诵凇菘",
    "圮鼙擗噼庀媲纰枇甓睥",
    "谅墚椋踉靓魉",
    "沮聚拒据巨具距踞锯俱",
    "拍排牌徘湃派俳蒎",
    "垃拉喇蜡腊辣啦剌摺邋",
    "雷镭蕾磊累儡垒擂类泪",
    "茹蠕儒孺如辱乳汝入褥",
    "懿苡薏弈奕挹弋呓咦咿",
    "嵘狨缛榕蝾",
    "羸诔荽咧漯嫘缧檑耒酹",
    "筛晒酾",
    "kgFzr",
    "拖托脱鸵陀驮驼椭妥拓",
    "垅茏泷珑栊胧砻癃",
    "戗炝锖锵镪襁蜣羟跫跄",
    "绉晁耖",
    "梓辎赀恣眦锱秭耔笫粢",
    "倘躺淌",
    "蘸栈占战站湛绽谵搌旃",
    "呸胚培裴赔陪配佩沛掊",
    "囟馨莘歆铽鑫",
    "绞剿教酵轿较叫佼僬茭",
    "洗系隙戏细僖兮隰郗茜",
    "娌嫠骊缡珞枥栎轹戾砺",
    "茔莺萦撄嘤膺滢潆瀛瑛",
    "喑狺夤氤铟瘾蚓霪龈",
    "娈琏楝殓臁膦裢蠊鲢",
    "氟符伏俘服浮涪福袱弗",
    "骸孩海氦亥害骇咴嗨颏",
    "然燃冉染苒髯",
    "菀纨绾琬脘畹蜿箢",
    "攴贽膣祉祗黹雉鸷痣蛭",
    "通桐酮瞳同铜彤童桶捅",
    "跌爹碟蝶迭谍叠佚垤堞",
    "鹗颚鳄",
    "蛾峨鹅俄额讹娥恶厄扼",
    "讧荭薨闳泓",
    "芝枝支吱蜘知肢脂汁之",
    "牍蠹笃髑黩",
    "荤昏婚魂浑混诨馄阍溷",
    "厕策侧册测刂帻恻",
    "诉肃夙谡蔌嗉愫簌觫稣",
    "妓继纪居丌乩剞佶佴脔",
    "相厢镶香箱襄湘乡翔祥",
    "嗫肀颞臬蹑",
    "唾乇佗坨庹沱柝砣箨舄",
    "芬酚吩氛分纷坟焚汾粉",
    "旰矸疳酐",
    "扑铺仆莆葡菩蒲埔朴圃",
    "报暴豹鲍爆勹葆宀孢煲",
    "皙穸蜥蟋舾羲粞翕醯鼷",
    "呤囹泠绫柃棂瓴聆蛉翎",
    "曰约越跃钥岳粤月悦阅",
    "涌永恿勇用俑壅墉慵邕",
    "獯汨宓弭脒敉糸縻麋",
    "砷申呻伸身深娠绅神沈",
    "摘斋宅窄债寨砦",
    "奋份忿愤粪偾瀵棼愍鲼",
    "咬舀药要耀夭爻吆崾徭",
    "钦侵亲秦琴勤芹擒禽寝",
    "偷投头透亠",
    "莎砂杀刹沙纱傻啥煞脎",
    "2816896AtSInD",
    "仞荏葚饪轫稔衽",
    "虽隋随绥髓碎岁穗遂隧",
    "妮霓倪泥尼拟你匿腻逆",
    "碍爱隘诶捱嗳嗌嫒瑷暧",
    "骆络倮荦摞猡泺椤脶镙",
    "巾筋斤金今津襟紧锦仅",
    "纣胄碡籀舳酎鲷",
    "詈罹锂鹂疠疬蛎蜊蠡笠",
    "挤几脊己蓟技冀季伎祭",
    "屑偕亵勰燮薤撷廨瀣邂",
    "迕妩骛牾焐鹉鹜蜈鋈鼯",
    "缋珲晖恚虺蟪麾",
    "训讯逊迅巽埙荀薰峋徇",
    "棉眠绵冕免勉娩缅面沔",
    "疵茨磁雌辞慈瓷词此刺",
    "凹敖熬翱袄傲奥懊澳坳",
    "吭忐铿",
    "铕疣蝣鱿黝鼬",
    "昌猖场尝常长偿肠厂敞",
    "儇谖萱揎馔泫洵渲漩璇",
    "诟岣遘媾缑觏彀鸲笱篝",
    "涣宦幻郇奂垸擐圜洹浣",
    "藩帆番翻樊矾钒繁凡烦",
    "冁谄谶蒇廛忏潺澶孱羼",
    "忮彘咫骘栉枳栀桎轵轾",
    "杠工攻功恭龚供躬公宫",
    "堤低滴迪敌笛狄涤翟嫡",
    "驸绂桴赙黻黼罘稃馥虍",
    "肓癀蟥篁鳇",
    "搀掺蝉馋谗缠铲产阐颤",
    "该改概钙盖溉丐陔垓戤",
    "涎弦嫌显险现献县腺馅",
    "拗嗷噢岙廒遨媪骜聱螯",
    "腓斐扉祓砩镄痱蜚篚翡",
    "稷瘠瘵虮笈笄暨跻跽霁",
    "鲚鲫髻麂",
    "瘕罅黠",
    "鞠拘狙疽驹菊局咀矩举",
    "蕉椒礁焦胶交郊浇骄娇",
    "蹿篡窜汆撺昕爨",
    "绌杵楮樗蜍蹰黜",
    "吹炊捶锤垂陲棰槌",
    "镎衲箬",
    "谕萸蓣揄喁圄圉嵛狳饫",
    "唧岌嵴洎彐屐骥畿玑楫",
    "墩吨蹲敦顿囤钝盾遁炖",
    "堆兑队对怼憝碓",
    "substr",
    "毙毖币庇痹闭敝弊必辟",
    "邈缈缪杪淼眇鹋蜱",
    "赴副覆赋复傅付阜父腹",
    "indexOf",
    "朋鹏捧碰坯堋嘭怦蟛",
    "甫抚辅俯釜斧脯腑府腐",
    "锨先仙鲜纤咸贤衔舷闲",
    "遽屦琚枸椐榘榉橘犋飓",
    "沸费芾狒悱淝妃绋绯榧",
    "溏瑭铴镗耥螗螳羰醣",
    "光广逛犷桄胱疒",
    "坊芳方肪房防妨仿访纺",
    "插叉茬茶查碴搽察岔差",
    "巫呜钨乌污诬屋无芜梧",
    "称谌抻嗔宸琛榇肜胂碜",
    "乖拐怪哙",
    "桷橛爝镢蹶觖",
    "癸桂柜跪贵刽匦刿庋宄",
    "屿禹宇语羽玉域芋郁吁",
    "芟潸姗骟膻钐疝蟮舢跚",
    "妫桧炅晷皈簋鲑鳜",
    "溻遢榻沓",
    "鹾蹉躜",
    "饰氏市恃室视试谥埘莳",
    "浼湄楣镅鹛袂魅",
    "蝇迎赢盈影颖硬映嬴郢",
    "嘬膪踹",
    "怖拊卟逋瓿晡钚醭",
    "镛甬鳙饔",
    "绁缬榭榍歙躞",
    "壑藿嗑嗬阖盍蚵翮",
    "德得的锝",
    "捐鹃娟倦眷卷绢鄄狷涓",
    "渖椹矧蜃",
    "桩庄装妆撞壮状丬",
    "莱来赖崃徕涞濑赉睐铼",
    "瞒馒蛮满蔓曼慢漫谩墁",
    "毂瞽罟钴锢瓠鸪鹄痼蛄",
    "剂悸济寄寂计记既忌际",
    "瞻毡詹粘沾盏斩辗崭展",
    "增憎曾赠缯甑罾锃",
    "菲非啡飞肥匪诽吠肺废",
    "奢赊蛇舌舍赦摄射慑涉",
    "反返范贩犯饭泛蘩幡犭",
    "桔杰捷睫竭洁结解姐戒",
    "挝蜗涡窝我斡卧握沃莴",
    "迹激讥鸡姬绩缉吉极棘",
    "而儿耳尔饵洱二贰迩珥",
    "绍劭苕潲蛸笤筲艄",
    "挢噍峤徼姣纟敫皎鹪蛟",
    "茵荫因殷音阴姻吟银淫",
    "织职直植殖执值侄址指",
    "锅郭国果裹过馘蠃埚掴",
    "筵酽魇餍鼹",
    "焉咽阉烟淹盐严研蜒岩",
    "XqZjZ",
    "呃愕屙婀轭曷腭硪锇锷",
    "楔些歇蝎鞋协挟携邪斜",
    "墟戌需虚嘘须徐许蓄酗",
    "砘礅盹镦趸",
    "氓萌蒙檬盟锰猛梦孟勐",
    "淖呢讷",
    "辊滚棍丨衮绲磙鲧",
    "睨铌鲵",
    "窖揭接皆秸街阶截劫节",
    "砒霹批披劈琵毗啤脾疲",
    "狞凝宁拧泞佞蓥咛甯聍",
    "吮瞬顺舜恂",
    "尉慰卫倭偎诿隈葳薇帏",
    "标彪膘表婊骠飑飙飚灬",
    "瘗癔翊衤蜴舣羿翳酏黟",
    "炯窘冂迥扃",
    "遭糟凿藻枣早澡蚤躁噪",
    "逾鱼愉渝渔隅予娱雨与",
    "璀榱隹",
    "仵阢邬圬芴庑怃忤浯寤",
    "员圆猿源缘远苑愿怨院",
    "浍骅桦铧稞",
    "薹肽炱钛跆鲐",
    "空恐孔控倥崆箜",
    "龋趣去诎劬蕖蘧岖衢阒",
    "枪呛腔羌墙蔷强抢嫱樯",
    "豌弯湾玩顽丸烷完碗挽",
    "阳氧仰痒养样漾徉怏泱",
    "吡哔狴庳愎滗濞弼妣婢",
    "夯痕很狠恨",
    "晚皖惋宛婉万腕剜芄苋",
    "郴臣辰尘晨忱沉陈趁衬",
    "操糙槽曹草艹嘈漕螬艚",
    "白柏百摆佰败拜稗薜掰",
    "苞胞包褒雹保堡饱宝抱",
    "昨左佐柞做作坐座阝阼",
    "琳林磷霖临邻鳞淋凛赁",
    "梭唆缩琐索锁所唢嗦娑",
  ];
  _0x13a1 = function () {
    return _0x1f7f8e;
  };
  return _0x13a1();
}
const pinyinUnicode = PinYin;
export function globalConvertPinyin(_0x857266) {
  const _0x4d9500 = _0x298a8d,
    _0x5a0cc1 = {
      kgFzr: _0x4d9500(0x36a) + "4",
      XqZjZ: function (_0x3d7ee2, _0x3c2f6f) {
        return _0x3d7ee2 > _0x3c2f6f;
      },
      EEHqC: function (_0x1cb580, _0x572e66) {
        return _0x1cb580 < _0x572e66;
      },
      uSOuv: function (_0x2aa685, _0x499d7b, _0x32ded8) {
        return _0x2aa685(_0x499d7b, _0x32ded8);
      },
    },
    _0x2e739a = _0x5a0cc1[_0x4d9500(0x292)][_0x4d9500(0x18f)]("|");
  let _0x49b16d = 0x1ced + -0x9ee + -0x12ff;
  while (!![]) {
    switch (_0x2e739a[_0x49b16d++]) {
      case "0":
        _0x29c636 = _0x29c636[_0x4d9500(0x267)](/ /g, "-");
        continue;
      case "1":
        var _0x492f0b = _0x857266[_0x4d9500(0x15b)];
        continue;
      case "2":
        var _0x29c636 = "";
        continue;
      case "3":
        while (
          _0x5a0cc1[_0x4d9500(0x32d)](
            _0x29c636[_0x4d9500(0x2f9)]("--"),
            -0x1c7e * 0x1 + -0x26e5 + 0x4363
          )
        ) {
          _0x29c636 = _0x29c636[_0x4d9500(0x267)]("--", "-");
        }
        continue;
      case "4":
        return _0x29c636;
      case "5":
        for (
          var _0x1afdda = -0x455 * 0x8 + 0x1 * -0x1b10 + -0x4 * -0xf6e;
          _0x5a0cc1[_0x4d9500(0x1ea)](_0x1afdda, _0x492f0b);
          _0x1afdda++
        ) {
          var _0x1a40f4 = _0x857266[_0x4d9500(0x2f5)](
              _0x1afdda,
              -0x3 * 0x6e6 + 0x1a23 + -0x570
            ),
            _0x1722c8 = _0x5a0cc1[_0x4d9500(0x394)](
              globalArraySearch,
              _0x1a40f4,
              pinyinUnicode
            );
          _0x29c636 += _0x1722c8;
        }
        continue;
    }
    break;
  }
}
function globalArraySearch(_0x5dd45f) {
  const _0x27b93b = _0x298a8d,
    _0x14027a = {
      JfwyT: function (_0x50b934, _0x30e237) {
        return _0x50b934 != _0x30e237;
      },
    };
  for (var _0x14f209 in pinyinUnicode) {
    if (
      _0x14027a[_0x27b93b(0x19f)](
        pinyinUnicode[_0x14f209][_0x27b93b(0x2f9)](_0x5dd45f),
        -(0xd5 * 0x1d + 0xbf9 + 0x2419 * -0x1)
      )
    ) {
      return _0x14f209;
      break;
    }
  }
  return _0x5dd45f;
}
