<template>
  <div class="page_box">
    <el-card shadow="never">
      <template #header>
        <div class="card_box">
          <div class="card_header">{{ props.title }}</div>
          <div class="look_btn" v-if="props.type == 3">
            <el-button v-show="!state.isEditBtn" @click="onEditCancel()"
              >取消</el-button
            >
            <el-button
              type="primary"
              v-show="!state.isEditBtn"
              @click="onEditSubmit()"
              >提交</el-button
            >
            <el-button
              type="primary"
              v-show="state.isEditBtn"
              @click="onEditBtn()"
              >编辑</el-button
            >
          </div>
        </div>
      </template>
      <div
        class="contract_box"
        v-for="(item, index) in state.list"
        :key="index"
      >
        <div class="contract_main">
          <el-row :gutter="20">
            <el-col :span="9">
              <el-form-item label="*服务商名称" :label-width="90">
                <div v-if="item.bodyList[0].isLook">
                  {{
                    onSelectText(
                      item.bodyList[0].value,
                      state.select_list_config
                    )
                  }}
                </div>
                <el-select
                  v-model.trim="item.bodyList[0].value"
                  placeholder="请输入"
                  @change="updateSelectDisabled"
                  v-else
                >
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    v-for="(item, index2) in state.arr[index]"
                    :key="index2"
                    :disabled="item.disabled"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="*合同编号" :label-width="90">
                <div v-if="item.bodyList[1].isLook">
                  {{ item.bodyList[1].value }}
                </div>
                <el-input
                  v-model.trim="item.bodyList[1].value"
                  placeholder="请输入"
                  v-else
                  @input="onInputFormat(item.bodyList[1], 'value', 3)"
                  :disabled="true"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6" class="col_btn_box" v-show="state.isEditBtn">
              <div class="look_btn">
                <el-button
                  v-show="
                    !(item.headerList[0].isBtn && item.headerList[1].isBtn)
                  "
                  @click="onCancel()"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  v-show="!item.headerList[0].isBtn"
                  @click="onSubmit(7, item, index)"
                  >提交</el-button
                >
                <el-button
                  type="primary"
                  v-show="!item.headerList[1].isBtn"
                  @click="onSubmit(9, item, index)"
                  >提交</el-button
                >
              </div>
              <div
                class="look_btn"
                v-show="
                  item.headerList[0].isBtn &&
                  item.headerList[1].isBtn &&
                  props.type == 3
                "
              >
                <el-button
                  type="primary"
                  v-if="item.headerList[0].isBtn"
                  @click="onBtnShow(1, index)"
                  >续签</el-button
                >
                <el-button
                  type="primary"
                  v-if="item.headerList[1].isBtn"
                  @click="onBtnShow(0, index)"
                  >补充</el-button
                >
              </div>
            </el-col>
            <el-col :span="12">
              <el-form-item label="*有效期" :label-width="90">
                <div v-if="item.bodyList[2].isLook">
                  {{ onDateShow(item.bodyList[2].value) }}
                </div>
                <el-date-picker
                  v-model.trim="item.bodyList[2].value"
                  type="daterange"
                  value-format="YYYY-MM-DD"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="截止日期"
                  v-else
                />
              </el-form-item>
            </el-col>
            <el-col :span="8"></el-col>
            <el-col :span="9">
              <el-form-item label="*主合同" :label-width="90">
                <PictureUploadComOnly
                  accept=".pdf"
                  :type="3"
                  text="支持 PDF 格式，且不超过10MB"
                  :img_key="item.bodyList[3].value"
                  v-model="item.bodyList[3].value"
                  :islook="item.bodyList[3].isLook"
                />
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="从属合同" :label-width="90">
                <PictureUploadComMultiple
                  accept=".pdf"
                  :type="3"
                  text="支持 PDF 格式，且不超过10MB"
                  :file_list="item.bodyList[4].value"
                  v-model="item.bodyList[4].value"
                  :islook="item.bodyList[4].isLook"
                />
              </el-form-item>
            </el-col>
            <!-- end -->
          </el-row>
        </div>
        <div class="contract_btn" v-show="!state.isEditBtn">
          <div>
            <el-button
              type="primary"
              plain
              @click="onAdd(item, index)"
              v-show="!state.isAddMax"
              >添加</el-button
            >
          </div>
          <div>
            <el-button
              type="danger"
              plain
              v-if="isDeleteBtn(item)"
              @click="onDelete(item, index)"
              >删除</el-button
            >
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, watch, computed } from "vue";
import {
  globalGetdate,
  onSelectText,
  onSelectFormat,
  onObjectCreate,
  onInputFormat,
} from "@/utils/date_tools";
import { useRouter, useRoute } from "vue-router";

import { baseDataConfig } from "./config.js";
import { managementGetList } from "@/api/gas-station/index.js";

const emits = defineEmits(["success", "submit"]);
const props = defineProps({
  title: {
    type: String,
    default: "上传合同",
  },
  baselist: {
    type: Array,
    default: [],
  },
  // 初始编辑类型，编辑模式，页面默认编辑值
  type: {
    type: [Number, String],
    default: 99999999,
  },
  isEditBtn: {
    type: Boolean,
    default: true,
  },
});

const router = useRouter();
const route = useRoute();
const detail_id = route.query.id; // query id

const state = reactive({
  input: "",
  list: [onObjectCreate(baseDataConfig), onObjectCreate(baseDataConfig)],
  arr: [],
  len: computed(() => state.list.length),
  isAddMax: false, // 服务商数量达到最大值
  api_data: null, // 接口数据
  select_list_config: [], // 初始select
  isEditBtn: props.isEditBtn,
}) as any;

onMounted(async () => {
  init();
});

function onEditCancel() {
  state.isEditBtn = true;
  onCancel();
}

function onEditBtn() {
  state.isAddMax = state.select_list_config.length <= state.list.length;
  state.isEditBtn = false;
}

async function init() {
  // 服务商名称
  const res = await managementGetList();
  // const list = onObjectCreate(res.data).map((item) => {
  //   item.facilitatorName = item.facilitatorName + "8";
  //   item.id = item.id + 2;
  //   return item;
  // });
  // res.data = [...res.data, ...list];
  state.select_list_config = onSelectFormat(res.data, "facilitatorName", "id");
  state.arr[0] = JSON.parse(JSON.stringify(state.select_list_config));
}

function onAdd(data: any, index: any) {
  // 禁用已经选中的部分
  const disabledList = state.list.map((item) => item.bodyList[0].value);
  const newList = JSON.parse(JSON.stringify(state.select_list_config));
  const newList2 = newList.map((item) => {
    item.disabled = disabledList.includes(item.value);
    return item;
  });
  // 选出最近侧初始值
  let newVlue = "";
  for (let i = 0; i < newList2.length; i++) {
    if (!newList2[i].disabled) {
      newVlue = newList2[i].value;
      break;
    }
  }

  const obj = onObjectCreate(baseDataConfig);
  obj.bodyList.forEach((item) => {
    item.isLook = false;
  });
  obj.bodyList[0].value = newVlue;
  state.list.splice(index + 1, 0, obj);

  state.arr.splice(index + 1, 0, newList2);

  updateSelectDisabled();

  if (state.select_list_config.length <= state.list.length) {
    state.isAddMax = true;
  }
}

// 刷新 select list
function updateSelectDisabled() {
  state.arr.forEach((item, index) => {
    const disabledList = state.list.map((item) => item.bodyList[0].value);
    if (state.list[index]) {
      const curVal = state.list[index].bodyList[0].value;
      item.forEach((item2) => {
        if (item2.value == curVal) {
          item2.disabled = false;
        } else {
          item2.disabled = disabledList.includes(item2.value);
        }
      });
    }
  });
}

// 删除按钮
function isDeleteBtn(data) {
  const is = data.bodyList.some((item) => !item.isLook);
  return state.len > 1 && is;
}

function onDelete(data: any, index: any) {
  state.isAddMax = false;
  state.list.splice(index, 1);
  state.arr.splice(index, 1);
  updateSelectDisabled();
}

// 日期展示
function onDateShow(arr: any = []) {
  if (arr.length > 0) {
    return String(arr).replace(",", " - ");
  }
  return "";
}

function onEditSubmit() {
  const idxList: any = [];
  state.list.forEach((item, index) => {
    const is = item.bodyList.some((item) => !item.isLook);
    if (is) {
      idxList.push(index);
    }
  });

  const list = dataFormat(state.list).map((item, index) => {
    const is = idxList.includes(index);
    item.selected = is;
    return item;
  });

  // 添加的数据
  emits("submit", 6, list);
}

function onSubmit(applyType, data, index) {
  const list = dataFormat(state.list).map((item, idx) => {
    item.selected = index == idx;
    return item;
  });
  state.api_data = list;
  emits("submit", applyType, list);
}

function onBtnShow(is, idx) {
  onCancel();
  const data = state.list[idx];
  if (is) {
    // 续签
    data.headerList[0].isBtn = false;
    data.bodyList.forEach((item, index) => {
      if (index > 0) {
        item.isLook = false;
      }
    });
  } else {
    // 补充
    data.headerList[1].isBtn = false;
    data.bodyList[4].isLook = false;
  }
}

function onCancel() {
  state.list.forEach((data) => {
    data.headerList.forEach((item) => {
      item.isBtn = true;
    });
    data.bodyList.forEach((item) => {
      item.isLook = true;
    });
  });
  initList(props.baselist);
}

// 数据处理
function dataFormat(arrData) {
  const list = arrData.map((item) => {
    const data = {};
    item.bodyList.forEach((item2) => {
      if (item2.type === "date") {
        data[item2.apiKeyName] = item2.value[0] || "";
        data[item2.apiKeyName2] = item2.value[1] || "";
      } else if (item2.type === "filemore") {
        data[item2.apiKeyName] = item2.value.join(",");
      } else {
        data[item2.apiKeyName] = item2.value;
      }
    });
    return data;
  });
  state.api_data = list;
  return list;
}

watch(
  () => state.list,
  (newValue) => {
    dataFormat(onObjectCreate(newValue));
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => props.isEditBtn,
  (newValue) => {
    state.isEditBtn = props.isEditBtn;
  },
  {
    immediate: true,
    deep: true,
  }
);

// watch(
//   () => state.select_list_config,
//   (newValue) => {
//     // const list = dataFormat(newValue);
//     // 修复多个select list
//     if (state.list.length > 1) {
//       // 多个数据
//       state.list.forEach((item, index) => {
//         // 禁用已经选中的部分
//         const disabledList = state.list.map((item) => item.value1);
//         const newList = JSON.parse(JSON.stringify(state.select_list_config));
//         const newList2 = newList.map((item) => {
//           item.disabled = disabledList.includes(item.value) ? true : false;
//           return item;
//         });
//         state.arr.splice(index + 1, 0, newList2);
//       });
//     }
//   },
//   {
//     immediate: true,
//     deep: true,
//   }
// );

function initList(initdatalist) {
  const list = onObjectCreate(initdatalist);
  const rtnList: any = [];
  list.forEach((data) => {
    let baseDataConfig2 = onObjectCreate(baseDataConfig);
    baseDataConfig2.bodyList.forEach((item: any) => {
      if (item.type === "date") {
        item.value = [
          globalGetdate(data[item.apiKeyName]).date,
          globalGetdate(data[item.apiKeyName2]).date,
        ];
      } else if (item.type === "filemore") {
        if (String(data[item.apiKeyName]).trim() == "") {
          item.value = [];
        } else {
          item.value = String(data[item.apiKeyName]).split(",");
        }
      } else {
        item.value = data[item.apiKeyName];
      }
    });
    rtnList.push(baseDataConfig2);
  });

  if (props.type === 2) {
    rtnList.forEach((item) => {
      item.bodyList.forEach((item) => {
        item.isLook = false;
      });
    });
  }
  const obj = onObjectCreate(baseDataConfig);
  if (props.type === 2) {
    obj.bodyList.forEach((item) => {
      item.isLook = false;
    });
  }
  state.list = rtnList.length > 0 ? rtnList : [obj];
}

watch(
  () => props,
  (newValue) => {
    initList(newValue.baselist);
  },
  {
    immediate: true,
    deep: true,
  }
);

defineExpose({
  api_data: computed(() => state.api_data),
});
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
