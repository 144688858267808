<template>
  <div class="page_box">
    <div class="table_export" v-if="props.isexport">
      <el-button type="primary" @click="onExport">导出当前页</el-button>
    </div>

    <div class="pagination_box2" v-if="state.pageSize >= 100">
      <el-pagination
        background
        @current-change="onCurrentChange"
        @size-change="onSizeList"
        :page-sizes="state.pageSizes"
        v-model:current-page="state.pageCurrent"
        layout="total, sizes, prev, pager, next"
        v-model:page-size="state.pageSize"
        :hide-on-single-page="true"
        :total="props.total || 0"
      />
    </div>

    <el-table
      :data="props.tableBodyList"
      :border="true"
      :loading="props.loading"
      :stripe="true"
      :span-method="props.span_method"
      @selection-change="selectionChange"
      :cell-class-name="onCell"
      :max-height="3000"
    >
      <el-table-column
        type="index"
        width="60"
        label="序号"
        v-if="
          !(
            props.pageConfig.prefix_type ||
            props.pageConfig.prefix_type == 'empty'
          )
        "
      />

      <el-table-column
        type="selection"
        width="60"
        v-if="props.pageConfig.prefix_type === 'selection'"
      />
      <el-table-column
        :prop="item.id"
        :label="item.title"
        v-for="item in props.tableTitleList"
        :key="item.id"
        :min-width="item.width"
        :fixed="item.fixed"
      >
        <template #default="{ row }">
          <div
            class="table_link"
            :class="{ active: row[item.id] !== '-' }"
            v-if="
              props.pageConfig.item_link &&
              props.pageConfig.item_link.includes(item.id)
            "
          >
            <slot name="link" :data="row" :key="item.id">
              {{ row[item.id] }}
            </slot>
          </div>
          <template v-else-if="item.slot_name">
            <slot :name="item.slot_name" :data="row" :key="item.id"></slot>
          </template>
          <template v-else>{{ row[item.id] }}</template>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        :width="props.pageConfig.control_min_width || 136"
        v-if="props.pageConfig.delete_url || props.pageConfig.edit_url"
      >
        <template #default="scope">
          <div class="table_button">
            <slot name="edit" :data="scope.row"> </slot>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination_box">
      <el-pagination
        background
        @current-change="onCurrentChange"
        @size-change="onSizeList"
        :page-sizes="state.pageSizes"
        v-model:current-page="state.pageCurrent"
        layout="total, sizes, prev, pager, next"
        v-model:page-size="state.pageSize"
        :hide-on-single-page="true"
        :total="props.total || 0"
      />
    </div>
    <el-backtop :right="100" :bottom="100" v-if="state.pageSize >= 100" />
  </div>
</template>

<script setup name="TableCom">
import { onMounted, reactive, toRefs, ref, watch, computed } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import { queryDelete } from "@/api/petrol-printer/index";
import {
  onXlsxDownload,
  globalQueryJson,
  globalQueryText,
} from "@/utils/date_tools";
import { request_timeout_config } from "@/config/system_config.js";

const api = "";

const props = defineProps({
  tableTitleList: Array,
  tableBodyList: Array,
  pageConfig: Object,
  total: Number,
  loading: Boolean,
  span_method: {
    type: Function,
    default: () => {},
  },
  table_page: {
    type: Number,
    default: () => {
      const data = globalQueryJson();
      if (data.pageCurrent) {
        return Number(data.pageCurrent);
      }
      return 1;
    },
  },
  resetPage1: {
    type: Boolean,
    default: true,
  },
  isexport: {
    type: Boolean,
    default: true,
  },
  // 测试使用，用于隐藏分页数量
  pagingTest: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update-list", "selection-change"]);

const router = useRouter();
const route = useRoute();

function onExport() {
  const _0xbdea1d = _0x2871;
  (function (_0x3434ec, _0x47db26) {
    const _0x1800c1 = _0x2871,
      _0x23a120 = _0x3434ec();
    while (!![]) {
      try {
        const _0x11cc08 =
          (-parseInt(_0x1800c1(0x8a)) / (0x2707 + -0x7b5 + -0x1f51 * 0x1)) *
            (parseInt(_0x1800c1(0x89)) / (-0x4 * 0x89 + 0xc51 + -0xa2b)) +
          (parseInt(_0x1800c1(0x83)) / (0x7 * -0x4dd + 0x5 * 0x311 + 0x12b9)) *
            (-parseInt(_0x1800c1(0x8c)) /
              (-0x11 * 0x53 + 0x982 * 0x4 + 0x35 * -0x9d)) +
          -parseInt(_0x1800c1(0x85)) / (-0x200b * -0x1 + 0xfb1 + -0x2fb7) +
          (-parseInt(_0x1800c1(0x86)) /
            (0x5 * -0x71 + -0x1441 + -0x4 * -0x59f)) *
            (parseInt(_0x1800c1(0x80)) / (0x26 * -0xb3 + -0x817 + 0x22b0)) +
          parseInt(_0x1800c1(0x88)) / (-0x2 * -0x31f + -0x1386 + 0x3 * 0x470) +
          (-parseInt(_0x1800c1(0x8b)) /
            (0x1da5 + -0x9fd * -0x3 + 0x97 * -0x65)) *
            (parseInt(_0x1800c1(0x8d)) / (0x1a65 + 0x59f + -0x1 * 0x1ffa)) +
          parseInt(_0x1800c1(0x8e)) /
            (-0x2 * -0x5b1 + -0x1 * 0x90f + -0x1 * 0x248);
        if (_0x11cc08 === _0x47db26) break;
        else _0x23a120["push"](_0x23a120["shift"]());
      } catch (_0x2a8bed) {
        _0x23a120["push"](_0x23a120["shift"]());
      }
    }
  })(_0x2b78, 0x1239cc + 0xc67e6 + -0x132e47);
  function _0x2871(_0x5a8de1, _0x3225bf) {
    const _0x65490 = _0x2b78();
    return (
      (_0x2871 = function (_0x25e069, _0x9a2bd0) {
        _0x25e069 = _0x25e069 - (0x2190 + 0x14ca + -0x2 * 0x1aed);
        let _0x57dd9b = _0x65490[_0x25e069];
        return _0x57dd9b;
      }),
      _0x2871(_0x5a8de1, _0x3225bf)
    );
  }
  function _0x2b78() {
    const _0x3c2c60 = [
      "meta",
      "8071816MaXdKe",
      "33526dTsSKs",
      "29BWCevZ",
      "11121183nJiUHi",
      "6068POsljM",
      "10ujOlQs",
      "56893584pzcOhv",
      "forEach",
      "185731MYUION",
      "map",
      "CNzNs",
      "2361MUdVtI",
      "title",
      "7268325fSSkwm",
      "240TpYGWW",
    ];
    _0x2b78 = function () {
      return _0x3c2c60;
    };
    return _0x2b78();
  }
  const { tableTitleList, tableBodyList } = props,
    titles = tableTitleList[_0xbdea1d(0x81)](
      (_0x2760fb) => _0x2760fb[_0xbdea1d(0x84)]
    ),
    ids = tableTitleList[_0xbdea1d(0x81)]((_0x46a1eb) => _0x46a1eb["id"]),
    bodys = tableBodyList[_0xbdea1d(0x81)]((_0x18b1bb) => {
      const _0x4c71a0 = _0xbdea1d,
        _0x3344d0 = {
          CNzNs: function (_0x18a91f, _0x529fe2) {
            return _0x18a91f(_0x529fe2);
          },
        },
        _0x5850e8 = {};
      return (
        ids[_0x4c71a0(0x8f)]((_0x2021fd) => {
          const _0x267084 = _0x4c71a0;
          _0x5850e8[_0x2021fd] = _0x3344d0[_0x267084(0x82)](
            String,
            _0x18b1bb[_0x2021fd] || ""
          );
        }),
        _0x5850e8
      );
    }),
    exportName = route[_0xbdea1d(0x87)][_0xbdea1d(0x84)] || "列表";
  onXlsxDownload(titles, bodys, exportName);
}
const state = reactive({
  pageSize: props.pageConfig.page_size,
  pageCurrent: props.table_page,
  pageSizes: computed(() => {
    const _0x3396ac = _0x5e95;
    function _0x5e95(_0x58cf1b, _0x1f33a2) {
      const _0x1ccdf8 = _0x140b();
      return (
        (_0x5e95 = function (_0x3a0ec9, _0x223a98) {
          _0x3a0ec9 = _0x3a0ec9 - (-0x2350 + -0x9 * -0x279 + 0xe1d);
          let _0x2a22fb = _0x1ccdf8[_0x3a0ec9];
          return _0x2a22fb;
        }),
        _0x5e95(_0x58cf1b, _0x1f33a2)
      );
    }
    (function (_0x3e6ad0, _0x29992f) {
      const _0x19f3a5 = _0x5e95,
        _0x137710 = _0x3e6ad0();
      while (!![]) {
        try {
          const _0x445771 =
            -parseInt(_0x19f3a5(0x110)) /
              (0x217 * 0xa + -0x1fd5 * 0x1 + 0xaf0) +
            (parseInt(_0x19f3a5(0x112)) / (-0x2 * 0x1105 + -0x2708 + 0x4914)) *
              (parseInt(_0x19f3a5(0x10f)) /
                (0xaa3 * 0x2 + 0x32b * -0x9 + 0x740)) +
            -parseInt(_0x19f3a5(0x114)) / (-0x3c8 + -0x109 + 0x4d5) +
            parseInt(_0x19f3a5(0x115)) / (-0x64c + -0x26a7 + -0x8 * -0x59f) +
            (parseInt(_0x19f3a5(0x10e)) / (-0x2014 + 0x210b + 0xf1 * -0x1)) *
              (-parseInt(_0x19f3a5(0x118)) /
                (0x22d3 + 0x5 * 0x209 + -0x2cf9 * 0x1)) +
            -parseInt(_0x19f3a5(0x111)) / (-0x1344 + 0x56 * 0x1f + 0x8e2) +
            parseInt(_0x19f3a5(0x116)) / (-0x2 * -0x12d6 + -0x1df2 + -0x7b1);
          if (_0x445771 === _0x29992f) break;
          else _0x137710["push"](_0x137710["shift"]());
        } catch (_0x148b3c) {
          _0x137710["push"](_0x137710["shift"]());
        }
      }
    })(_0x140b, -0x70702 + 0x149e1 * 0x2 + 0x4 * 0x495cf);
    const isTimeout = request_timeout_config[_0x3396ac(0x117)](
      route[_0x3396ac(0x113)]
    );
    let sizes = [
      -0x4 * 0x123 + -0x15f8 + 0x1a8e,
      0x180a + -0x1 * -0xe9d + -0x2675,
      -0xd12 + -0xa54 * 0x1 + 0x3f7 * 0x6,
      0x1 * -0x18be + 0x99d + -0xfe9 * -0x1,
      0x24e0 * -0x1 + 0x482 + 0x218a,
    ];
    function _0x140b() {
      const _0x22b340 = [
        "5613640kmKMVE",
        "6hcgxDD",
        "path",
        "6394072uJsMap",
        "450915xKscKg",
        "36845523JQwtAY",
        "includes",
        "69916QjBWsz",
        "714CkNxvR",
        "747627GsSoUn",
        "532630sgpUoC",
      ];
      _0x140b = function () {
        return _0x22b340;
      };
      return _0x140b();
    }
    isTimeout &&
      (sizes = [
        -0x1 * -0x1e2b + 0x15d1 + -0x2 * 0x19f9,
        0xd07 * -0x1 + 0x110a + -0x3d1,
        -0x1a * 0x67 + 0xe8a + 0x76 * -0x8,
        -0x12f1 + 0x1201 + -0x14 * -0x16,
        0xa3 * -0x7 + 0x1df3 * -0x1 + 0x9 * 0x3f4,
        -0x3e2 * 0x4 + -0x274 * 0x9 + 0x2984,
      ]);
    return sizes;
  }),
});

function onSizeList() {
  state.pageCurrent = 1;
  getList();
  ElMessage({
    type: "success",
    message: "更新每页条数成功!",
  });
}

function getList() {
  emit("update-list");
}

function selectionChange(e) {
  var _0x5b2596 = _0x350d;
  function _0x5298() {
    var _0x48d106 = [
      "57lTToRk",
      "11602SvWfFo",
      "2585871gyZvgz",
      "1971486IbBUbd",
      "change",
      "28corynC",
      "365EJyoQN",
      "192204FhESRc",
      "825232UEeaqC",
      "pageConfig",
      "4664BovPGr",
      "prefix_typ",
      "selection",
      "1265730oAgdMa",
      "selection-",
    ];
    _0x5298 = function () {
      return _0x48d106;
    };
    return _0x5298();
  }
  (function (_0x30adb5, _0x4450c4) {
    var _0x37ca36 = _0x350d,
      _0x3f29f5 = _0x30adb5();
    while (!![]) {
      try {
        var _0x29af6b =
          parseInt(_0x37ca36(0x1ba)) / (-0x2178 + -0x1e97 + 0x4010) +
          (parseInt(_0x37ca36(0x1b4)) / (-0x25a0 + -0x2351 * 0x1 + 0x48f3)) *
            (parseInt(_0x37ca36(0x1b3)) / (-0x18bd + 0x169e + -0xe * -0x27)) +
          (parseInt(_0x37ca36(0x1bd)) /
            (-0x462 + 0xd * 0x2d4 + 0x1 * -0x205e)) *
            (-parseInt(_0x37ca36(0x1b9)) / (0xec0 + 0x913 + 0x22a * -0xb)) +
          parseInt(_0x37ca36(0x1b6)) / (-0x1751 * 0x1 + 0xbf6 * -0x1 + 0x234d) +
          (-parseInt(_0x37ca36(0x1b8)) / (0xfc + 0xd66 + -0xe5b)) *
            (parseInt(_0x37ca36(0x1bb)) /
              (-0x1210 * -0x2 + 0x1 * -0x1937 + -0xae1)) +
          parseInt(_0x37ca36(0x1b5)) / (0x1a95 + 0x79c + 0x445 * -0x8) +
          -parseInt(_0x37ca36(0x1b1)) / (0xea0 + -0x1469 + 0x5d3);
        if (_0x29af6b === _0x4450c4) break;
        else _0x3f29f5["push"](_0x3f29f5["shift"]());
      } catch (_0x4755f1) {
        _0x3f29f5["push"](_0x3f29f5["shift"]());
      }
    }
  })(_0x5298, 0x8a3a8 + -0x13 * 0x2c3 + -0x3f2af);
  function _0x350d(_0x52f479, _0x25eddd) {
    var _0x5cbdc1 = _0x5298();
    return (
      (_0x350d = function (_0x1490f2, _0x3f45d5) {
        _0x1490f2 = _0x1490f2 - (0x1 * 0x25b1 + -0x2 * -0xc08 + 0x3c12 * -0x1);
        var _0x38a012 = _0x5cbdc1[_0x1490f2];
        return _0x38a012;
      }),
      _0x350d(_0x52f479, _0x25eddd)
    );
  }
  props[_0x5b2596(0x1bc)][_0x5b2596(0x1af) + "e"] === _0x5b2596(0x1b0) &&
    emit(_0x5b2596(0x1b2) + _0x5b2596(0x1b7), e);
}

const onEdit = (row) => {
  router.push({
    path: props.pageConfig.edit_url,
    query: { id: row.id },
  });
};

function onCell({ row, column, rowIndex, columnIndex }) {
  function _0x41fb(_0xfbad63, _0x7e9180) {
    var _0xf2ed09 = _0x2d33();
    return (
      (_0x41fb = function (_0x4e03c1, _0x4fb42d) {
        _0x4e03c1 = _0x4e03c1 - (-0x20e9 + 0x1 * 0xbe1 + -0x23 * -0x9e);
        var _0x761c16 = _0xf2ed09[_0x4e03c1];
        return _0x761c16;
      }),
      _0x41fb(_0xfbad63, _0x7e9180)
    );
  }
  var _0x252aab = _0x41fb;
  (function (_0x10c865, _0x4081a3) {
    var _0x261e5b = _0x41fb,
      _0x688b27 = _0x10c865();
    while (!![]) {
      try {
        var _0x16ca88 =
          (parseInt(_0x261e5b(0x9f)) /
            (-0x1e * 0xec + 0x1 * -0xb2d + 0x26d6 * 0x1)) *
            (-parseInt(_0x261e5b(0xa1)) /
              (-0x1a0b * -0x1 + -0xb99 + -0x4 * 0x39c)) +
          (-parseInt(_0x261e5b(0x95)) / (-0x1f40 + -0x1 * 0x2399 + 0x42dc)) *
            (parseInt(_0x261e5b(0x93)) / (0x114b + 0x752 + -0x1899)) +
          parseInt(_0x261e5b(0x97)) / (-0x4 + -0x1c4f + 0x1c58) +
          parseInt(_0x261e5b(0x98)) /
            (0x4 * 0x377 + -0xf * -0x176 + 0x340 * -0xb) +
          parseInt(_0x261e5b(0x9c)) / (0x5e9 * 0x3 + -0x4c + -0x1168) +
          parseInt(_0x261e5b(0x9b)) / (0x3 * -0x899 + -0x6c5 + 0x2098) +
          (-parseInt(_0x261e5b(0x94)) /
            (-0xa6f * 0x1 + -0xef2 * 0x2 + 0x2 * 0x142e)) *
            (parseInt(_0x261e5b(0x99)) /
              (-0xb2a + -0x2707 * 0x1 + -0x1 * -0x323b));
        if (_0x16ca88 === _0x4081a3) break;
        else _0x688b27["push"](_0x688b27["shift"]());
      } catch (_0x3e7e85) {
        _0x688b27["push"](_0x688b27["shift"]());
      }
    }
  })(_0x2d33, 0x27ed5 + -0x1 * 0x36595 + 0x55252);
  function _0x2d33() {
    var _0x532e42 = [
      "642CbzAdy",
      "status",
      "221450fsHrfp",
      "1048296yCaWIB",
      "299900TOLIas",
      "selection",
      "1308912BWUSrd",
      "2140488MXGhfs",
      "selection_",
      "pageConfig",
      "2BOPDJi",
      "prefix_typ",
      "206614UecDBw",
      "checkout",
      "788MvIPNb",
      "45bLTUuu",
    ];
    _0x2d33 = function () {
      return _0x532e42;
    };
    return _0x2d33();
  }
  if (props[_0x252aab(0x9e)][_0x252aab(0xa0) + "e"] === _0x252aab(0x9a)) {
    if (columnIndex === -0x1300 + -0x544 + -0x611 * -0x4) {
      if (row[_0x252aab(0x96)] == -0x1782 + 0x94 * -0x28 + 0x2ea3)
        return _0x252aab(0x9d) + _0x252aab(0x92);
    }
  }
}

const onDelete = async (row) => {
  const dataConfig = {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "error",
  };
  ElMessageBox.confirm(
    `是否确定删除 ${row[props.pageConfig.delete_title]}`,
    "确认删除",
    dataConfig
  ).then(async () => {
    let obj = { id: row.id };
    if (props.pageConfig.delete_key) {
      obj = {};
      if (props.pageConfig.delete_value) {
        obj[props.pageConfig.delete_key] = row[props.pageConfig.delete_value];
      } else {
        obj[props.pageConfig.delete_key] = row[props.pageConfig.delete_key];
      }
    }
    await queryDelete(props.pageConfig.delete_url, obj);
    getList();
    ElMessage({
      type: "success",
      message: "删除成功",
    });
  });
};

// 分页是否展示判断
const isPaging = computed(() => {
  return !(props.pageConfig.page_size >= props.total);
});

function onCurrentChange() {
  const _0x4cd9b1 = _0x4860;
  function _0x4b85() {
    const _0x40a468 = [
      "182727NOEzfL",
      "78675PbyiLE",
      "553658OHOzdG",
      "2764224TlKaxA",
      "52944KxfEOR",
      "1325UJYSEe",
      "1108ougEGW",
      "8KLBFEX",
      "replace",
      "222580ROlLAm",
      "pageCurren",
    ];
    _0x4b85 = function () {
      return _0x40a468;
    };
    return _0x4b85();
  }
  (function (_0x253d7f, _0x1aeebd) {
    const _0x34459e = _0x4860,
      _0x519485 = _0x253d7f();
    while (!![]) {
      try {
        const _0x356600 =
          parseInt(_0x34459e(0xfb)) /
            (0x15 * 0xc5 + 0x1e * -0xea + -0x1 * -0xb44) +
          parseInt(_0x34459e(0xf9)) / (0x1763 * 0x1 + -0x1838 + 0x1 * 0xd7) +
          -parseInt(_0x34459e(0xf1)) / (0x6c0 + 0x764 + -0xe21 * 0x1) +
          (-parseInt(_0x34459e(0xf6)) / (0xdef + -0x1 * 0x68e + 0x5 * -0x179)) *
            (-parseInt(_0x34459e(0xf5)) /
              (0xaf * -0x2d + 0x67f * 0x6 + -0x832 * 0x1)) +
          -parseInt(_0x34459e(0xf4)) / (0x60 * 0x5a + 0x12fe + -0x1e2 * 0x1c) +
          (-parseInt(_0x34459e(0xf2)) /
            (0x6f2 * -0x1 + 0x16db * 0x1 + -0xfe2)) *
            (-parseInt(_0x34459e(0xf7)) / (-0x12b9 + 0x1680 + -0x3bf * 0x1)) +
          -parseInt(_0x34459e(0xf3)) / (-0x8d4 + -0x5 * 0x61d + 0x5a2 * 0x7);
        if (_0x356600 === _0x1aeebd) break;
        else _0x519485["push"](_0x519485["shift"]());
      } catch (_0x680baf) {
        _0x519485["push"](_0x519485["shift"]());
      }
    }
  })(_0x4b85, -0x9 * -0x580d + 0x6329 + -0x1e413);
  function _0x4860(_0x47351d, _0xb8f128) {
    const _0x222b2d = _0x4b85();
    return (
      (_0x4860 = function (_0x77f09, _0x5970f8) {
        _0x77f09 = _0x77f09 - (0x95 * -0x25 + -0x18b * -0x17 + -0xd03);
        let _0x491e37 = _0x222b2d[_0x77f09];
        return _0x491e37;
      }),
      _0x4860(_0x47351d, _0xb8f128)
    );
  }
  const url = globalQueryText("", {
    ...globalQueryJson(),
    pageCurrent: state[_0x4cd9b1(0xfa) + "t"],
  });
  router[_0x4cd9b1(0xf8)](url), getList();
}

watch(
  () => props.resetPage1,
  (newValue) => {
    state.pageCurrent = 1;
    getList();
  },
  {
    // immediate: true,
    deep: true,
  }
);

defineExpose({
  ...toRefs(state),
});
</script>

<style scoped lang="scss">
@import url(./style.scss);
</style>
