import request from "@/utils/request";

// 首页统计
export function saleHomeStatistics() {
  return request.get(`sale/home/statistics`);
}
// 省下拉列表
export function saleStationProvinceList() {
  return request.get(`sale/station/provinceList`);
}
// 油站列表
export function saleStationList(data, params) {
  return request.post(`sale/station/list`, data, {
    params,
  });
}
// 查询业务经理
export function listBusinessManagerUser() {
  return request.get(`system/user/listBusinessManagerUser`);
}
// 油站详情-油站折扣部分作废，后端一个页面写了多个接口，这里包含已发布渠道列表
export function saleStationDetail(stationNum) {
  return request.get(`sale/station/detail`, {
    params: { stationNum },
  });
}
// 油站详情-油站折扣
export function settlementConfigQuery(gasId) {
  return request.get(`settlementConfig/querySaleSettlement/${gasId}`);
}
// 汇总统计
export function saleDataReportStatisticsSummaryList(data, params) {
  return request.post(`sale/data/report/statistics/summary/list`, data, {
    params,
  });
}
// 汇总统计详情
export function saleDataReportStatisticsSummaryDetail(data, params) {
  return request.post(`sale/data/report/statistics/summary/detail`, data, {
    params,
  });
}
// 油站统计列表
export function saleDataReportStatisticsStationList(data, params) {
  return request.post(`sale/data/report/statistics/station/list`, data, {
    params,
  });
}
// 油站统计统计
export function saleDataReportStatisticsStationSum(data, params) {
  return request.post(`sale/data/report/statistics/station/sum`, data, {
    params,
  });
}
// 油站统计详情
export function saleDataReportStatisticsStationDetail(data, params) {
  return request.post(`sale/data/report/statistics/station/detail`, data, {
    params,
  });
}
// 流水排名
export function saleDataReportRankFlow(data, params) {
  return request.post(`sale/data/report/rank/flow`, data, {
    params,
  });
}
// 签约排名
export function saleDataReportRankSign(data, params) {
  return request.post(`sale/data/report/rank/sign`, data, {
    params,
  });
}
// 修改油枪价
export function oilGunpriceUpdateOilGunPrice(data) {
  return request.post(`oil/gunprice/updateOilGunPrice`, data);
}
// 修改直降优惠
export function settlementConfigSave(data) {
  return request.post(`settlementConfig/save`, data);
}
