import Layout from "@/layout";
import gas_station_router_config from "./routers/gas-station";
import service_manage_router_config from "./routers/service-manage";
import channel_manage_router_config from "./routers/channel-manage";
import publish_manage_router_config from "./routers/publish-manage";
import printer_manage_router_config from "./routers/printer-manage";
import nation_manage_router_config from "./routers/nation-manage";
import gas_station_manage_router_config from "./routers/gas-station-manage";
import order_manage_router_config from "./routers/order-manage";
import oil_gun_config from "./routers/oil-gun";
import operation_record_config from "./routers/operation-record";
import marketing_manage_router_config from "./routers/marketing-manage";
import account_back_router_config from "./routers/account-back";
import merchant_config from "./routers/merchant-manage.js";
import gas_station_ad_router_config from "./routers/gas-station-ad.js";
import account_back_all_router_config from "./routers/account-back-all.js";
import operate_manage_router_config from "./routers/operate-manage.js";
import driver_manage_router_config from "./routers/driver-manage.js";
import other_router_config from "./routers/other.js";
import report_forms_router_config from "./routers/report-forms.js";
import custom_small_router_config from "./routers/custom-small-router.js";
import electron_coupon_manage_router_config from "./routers/electron-coupon-manage.js";
import supplier_manage_router_config from "./routers/supplier-manage.js";

const custom_router_config = [
  ...supplier_manage_router_config,
  ...electron_coupon_manage_router_config,
  ...custom_small_router_config,
  ...report_forms_router_config,
  ...other_router_config,
  ...driver_manage_router_config,
  ...operate_manage_router_config,
  ...account_back_all_router_config,
  ...gas_station_ad_router_config,
  ...merchant_config,
  ...account_back_router_config,
  ...operation_record_config,
  ...oil_gun_config,
  ...gas_station_router_config,
  ...service_manage_router_config,
  ...channel_manage_router_config,
  ...publish_manage_router_config,
  ...printer_manage_router_config,
  ...nation_manage_router_config,
  ...gas_station_manage_router_config,
  ...order_manage_router_config,
  ...marketing_manage_router_config,
  {
    path: "/test",
    component: () => import("@/views/0test.vue"),
    hidden: true,
  },
];

export default custom_router_config;
